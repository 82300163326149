.scc-list {
  @include list__reset;
  > li:not(:last-child) {
    margin-bottom: .8rem;
    padding-bottom: .8rem;
    border-bottom: 2px solid $color--gray-lightest;
  }
}

.scc-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__product {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    &__image {
      display: none;
      // width: 100px;
      img {
        @include img-fluid;
      }
      @include media-breakpoint-up(md) {
        display: block;
        margin-right: 1rem;
      }
    }
    &__title {
      font-weight: bold;
    }
  }

  .price__amount {
    margin-right: .2rem
  }

  // &__price {
  //
  // }

  &__total {
    flex: 0 0 auto;
    text-align: right;
    padding-left: 1rem;
  }

  &__price {
    color: $color--gray-light;
  }

  &__total {
    .price__amount {
      font-size: 1.5rem;
    }
  }
}


// Toatal table
.field-name-commerce-order-total {
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
  padding-top: .5rem;
  text-align: right;
  border-top: 1px solid $color--gray-lightest;
  color: $color--gray-light;

  .price__amount {
    margin-right: .2rem
  }

  table, table.table, table.table.table-striped {
    background: transparent;
    width: auto;


    tr {
      background: transparent!important;
    }

    th, td {
      background: transparent;
      padding: .5rem;
      border-top: 1px solid $color--gray-lightest;
      vertical-align: middle;
      // line-height: 1.3;
    }

    tr:first-child td {
      border-top: none;
    }

    tr.component-type-commerce-price-formatted-amount {
      color: $color--gray-dark;
      // font-weight: bold;
      td {
        border-top: 2px solid $color--gray;
        font-size: 1.2rem;
        // vertical-align: bottom;
        // line-height: 1;
      }
      .price__amount {
        font-size: 1.5rem;
      }
    }

    td.component-total {
      text-align: right;
    }
  }
}
