.sf-menu {
  @include clearfix;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
  li {
    white-space: nowrap;
    transition: background .2s;
  }
  a, .nolink {
    padding: .75rem 1rem;
    color: $color--gray;
    text-decoration: none;
    cursor: pointer;
  }

  // Level 1
  //
  >li>a,
  >li>.nolink {
    //background-color: $gray-blue-50;
    padding: .875rem;
    font-style: $font-size-sm;
    font-weight: bold;
    text-transform: uppercase;
  }

  // Level 2+
  //
  ul {
    background: lighten($gray-50, 2%);
    width: auto!important;
    min-width: 12em;
    box-shadow: 0 10px 20px 0 rgba(#000, .1);
    border-bottom: none;
    font-size: .875rem;
    .nolink, a {
      color: $gray-500;
    }
    ul {
      //background: transparentize(darken($sf-menu-link-bg, 2%), 0);
    }
  }
  ul li {
    border-bottom: 1px solid $color--gray-lightest;
    &.last {
      border-bottom: none;
    }
  }
  // Arrows
  .sf-with-ul,
  .nolink.sf-with-ul {
    padding-right: 22px;
    position: relative;
    &:before {
      @include make-fa-icon-ready;
      @include fa-icons;
      content: "\f078";
      margin-top: -6px;
      font-size: .6rem;
      line-height: .6rem;
      position: absolute;
      right: 8px;
      top: 50%;
    }
  }
  ul sf-with-ul,
  ul .nolink.sf-with-ul {
    &:before {
      //@extend .fa-chevron-down;
      //color: $sf-menu-link-arrow-down-color;
    }
  }
  span.sf-sub-indicator {
    display: none;
  }
}

.sf-menu li:hover,
.sf-menu li.sfHover {
  background: lighten($gray-50, 2%);
  transition: none;
  color: $color--gray-dark;
  //box-shadow: 0 10px 20px 0 rgba(#000, .1);
  li:hover,
  li.sfHover {
    background: $white;
  }
  ul {
    top: 100% !important;
  }
  ul ul {
    top: 0 !important;
  }
}
