table {
  @extend .table;

  thead {
    background: $color--gray-dark;

    th {
      border-color: $color--gray-dark;
      color: $color--gray-lightest;
      font-weight: normal;

      a {
        color: $color--gray-lightest;
        text-decoration: underline;
        &:hover {
          color: $color--gray-lightest;
          text-decoration: none;
        }
      }
    }
  }
}

.views-align-left {
  text-align: left;
}

.views-align-center {
  text-align: center;
}

.views-align-right {
  text-align: right;
}
