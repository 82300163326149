.action-links {
  @include list__reset;
  display: flex;
  margin-bottom: 1.5rem;

  a {
    @include button;
    @include button-sizing;
    @include button-primary;
  }
}