.testimonial {
  &__title {
    margin-bottom: 1rem;
    color: $color--gray-dark;
    font-weight: bold;
  }

  &__content {
    margin-bottom: 2rem;
    color: $color--gray-light;
  }

  &__person {
    display: flex;
    align-items: center;
    &__image {
      margin-right: .5rem;
      img {
        @include img-fluid;
        border-radius: 50%;
      }
    }
    &__content {
      color: $color--gray-light;
      .name {
        color: $color--gray-dark;
        font-weight: bold;
      }
    }
  }
}
