#commerce-checkout-form-review {

  .checkout-help {
    display: none;
  }
}

.checkout_review  table {
  .form-item {
    margin-bottom: 0;
    label {
      margin: 0 .5rem 0 0;
      color: $color--gray-light;
      &:after {
        content: ":"
      }
    }
  }
  tr.pane-title {
    margin: 0;
    border: none;
    font-size: 1rem;
    &:after {
      display: none;
    }
    td {
      border: none;
    }
  }

  tr.pane-data {
    td {
      border: none;
    }
  }
}
