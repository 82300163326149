// --------------------------
// User login form
// --------------------------

.user-login-form-wrapper {
  background: #FFF;
  box-shadow: 0 1px 2px 0 transparentize(#000, .9);
  padding: 1rem  2rem;
  @include media-breakpoint-up(md) {
    padding: 2rem;
  }

  nav.tabs {
    margin-bottom: 2rem!important;
  }

  input[type="submit"] {
    @include button;
    @include button-primary;
    @include button-sizing(large);
    display: block;
    width: 100%;
  }

  form > div > .form-wrapper {
    padding: 0;
  }

  // Делот за регострација со
  // e-mail адреса е нешо покењат.
  #edit-account {
    border: none;
    padding: 0;
  }
}


//
// Hybrid Widget
//
.hybridauth-widget-wrapper {
  ul.hybridauth-widget {
    display: flex;
    li {
      flex: 1;
    }
  }
}

.btn-hybrid {
  padding-top: 12px;
  padding-bottom: 12px;
}

a.hybridauth-widget-provider {
  color: #FFF;
  text-align: center;
  text-decoration: none;

  .facebook-f {
    background: #3b5998;
    span {
      @include media-breakpoint-down(sm) {
        //display: none;
      }
    }
  }

  .google {
    background: #d34836;
    span {
      @include media-breakpoint-down(sm) {
        //display: none;
      }
    }
  }

  .twitter {
    background: #00aced;
    span {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

//
// OR separator
//
.or-separator {
  position: relative;
  margin: 1.5em 0;
  text-align: center;
  &:after {
    content: "";
    width: 100%;
    border-bottom: 1px solid $color--gray-light;
    position: absolute;
    top: calc(50% + 1px);
    left: 0;
  }
  span {
    background: #FFF;
    padding: 0 5px;
    font-weight: bold;
    position: relative;
    z-index: 5;
  }
}

