// Mobile - Off-canvas menu
// Регионот се принта во хедер tpl фајлот.


.region-off-canvas {
  max-height: 0;
  overflow: hidden;
  transition: all .2s ease-in-out;
  &.open {
    max-height: 50vh;
  }

  .block-system-main-menu ul.menu {
    @include list__reset;

    a, .nolink {
      display: block;
      padding: .5rem 2rem;
      border-bottom: 1px solid lighten($color--gray-light, 15%);
      font-size: 1.4rem;
      color: $color--gray-dark;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      &.active-trail, &.active {
        background-color: $color--green-lightest;
        font-weight: bold;
      }
    }


    // Submenu
    //
    li.expanded {
      ul.menu {
        background-color: $color--gray-lightest;
        overflow: hidden;
        max-height: 0;
        transition: all .2s ease-in-out;
        a, .nolink {
          border-bottom: 1px solid lighten($color--gray-light, 15%);
          padding: 1rem 2rem;
          font-size: 1rem;
          &.active-trail {
            background-color: transparent;
            font-weight: bold;
          }
        }

        &.open {
          max-height: 600px;
        }
      }
    }

  }
}