.messages {
  margin: 2rem 0;
  padding: 1rem;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: $color--gray-light;
  &.status {
    background: $color--green-lightest;
    border-color: $color--green;
    color: $color--green-dark;
  }
  &.warning {
    background: $yellow-100;
    border-color: $yellow-800;
    color: $yellow-800;
  }
  &.error {
    background: $red-50;
    border-color: $red-600;
    color: $red-600;
  }
}
