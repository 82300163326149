//## Material design color palette
//## https://www.google.com/design/spec/style/color.html

//## Color shame mixer: https://www.materialpalette.com



//== Red
//
$red-50: 		#ffebee!default;
$red-100: 	#ffcdd2!default;
$red-200: 	#ef9a9a!default;
$red-300: 	#e57373!default;
$red-400: 	#ef5350!default;
$red-500: 	#f44336!default;
$red-600: 	#e53935!default;
$red-700: 	#d32f2f!default;
$red-800: 	#c62828!default;
$red-900: 	#b71c1c!default;

$red-A100:	#ff8a80!default;
$red-A200:	#ff5252!default;
$red-A400:	#ff1744!default;
$red-A700:	#d50000!default;

$red: $red-500!default;



//== Pink
//
$pink-50: 	#fce4ec!default;
$pink-100: 	#f8bbd0!default;
$pink-200: 	#f48fb1!default;
$pink-300: 	#f06292!default;
$pink-400: 	#ec407a!default;
$pink-500: 	#e91e63!default;
$pink-600: 	#d81b60!default;
$pink-700: 	#c2185b!default;
$pink-800: 	#ad1457!default;
$pink-900: 	#880e4f!default;

$pink-A100: #ff80ab!default;
$pink-A200: #ff4081!default;
$pink-A400: #f50057!default;
$pink-A700: #c51162!default;

$pink: $pink-500!default;



//== Purple
//
$purple-50: 	#f3e5f5!default;
$purple-100:	#e1bee7!default;
$purple-200: 	#ce93d8!default;
$purple-300: 	#ba68c8!default;
$purple-400: 	#ab47bc!default;
$purple-500: 	#9c27b0!default;
$purple-600: 	#8e24aa!default;
$purple-700: 	#7b1fa2!default;
$purple-800: 	#6a1b9a!default;
$purple-900: 	#4a148c!default;

$purple-A100: #ea80fc!default;
$purple-A200: #e040fb!default;
$purple-A400: #d500f9!default;
$purple-A700: #aa00ff!default;

$purple: $purple-500!default;



//== Deep Purple
//
$purple-deep-50: 		#ede7f6!default;
$purple-deep-100: 	#d1c4e9!default;
$purple-deep-200: 	#b39ddb!default;
$purple-deep-300: 	#9575cd!default;
$purple-deep-400: 	#7e57c2!default;
$purple-deep-500: 	#673ab7!default;
$purple-deep-600: 	#5e35b1!default;
$purple-deep-700: 	#512da8!default;
$purple-deep-800: 	#4527a0!default;
$purple-deep-900: 	#311b92!default;

$purple-deep-A100: 	#b388ff!default;
$purple-deep-A200: 	#7c4dff!default;
$purple-deep-A400: 	#651fff!default;
$purple-deep-A700: 	#6200ea!default;

$purple-deep: $purple-deep-500!default;



//== Indigo
//
$indigo-50: 	#e8eaf6!default;
$indigo-100: 	#c5cae9!default;
$indigo-200: 	#9fa8da!default;
$indigo-300: 	#7986cb!default;
$indigo-400: 	#5c6bc0!default;
$indigo-500: 	#3f51b5!default;
$indigo-600: 	#3949ab!default;
$indigo-700: 	#303f9f!default;
$indigo-800: 	#283593!default;
$indigo-900: 	#1a237e!default;

$indigo-A100: #8c9eff!default;
$indigo-A200: #536dfe!default;
$indigo-A400: #3d5afe!default;
$indigo-A700: #304ffe!default;

$indigo: $indigo-500!default;



//== Blue
//
$blue-50: 	#e3f2fd!default;
$blue-100: 	#bbdefb!default;
$blue-200: 	#90caf9!default;
$blue-300: 	#64b5f6!default;
$blue-400: 	#42a5f5!default;
$blue-500: 	#2196f3!default;
$blue-600: 	#1e88e5!default;
$blue-700: 	#1976d2!default;
$blue-800: 	#1565c0!default;
$blue-900: 	#0d47a1!default;

$blue-A100: #82b1ff!default;
$blue-A200: #448aff!default;
$blue-A400: #2979ff!default;
$blue-A700: #2962ff!default;

$blue: $blue-500!default;



//== Light Blue
//
$blue-light-50: 		#e1f5fe!default;
$blue-light-100:		#b3e5fc!default;
$blue-light-200: 	#81d4fa!default;
$blue-light-300: 	#4fc3f7!default;
$blue-light-400: 	#29b6f6!default;
$blue-light-500: 	#03a9f4!default;
$blue-light-600: 	#039be5!default;
$blue-light-700: 	#0288d1!default;
$blue-light-800: 	#0277bd!default;
$blue-light-900: 	#01579b!default;

$blue-light-A100: 	#80d8ff!default;
$blue-light-A200: 	#40c4ff!default;
$blue-light-A400: 	#00b0ff!default;
$blue-light-A700: 	#0091ea!default;

$blue-light: $blue-light-500!default;



//== Cyan
//
$cyan-50: 	#e0f7fa!default;
$cyan-100: 	#b2ebf2!default;
$cyan-200: 	#80deea!default;
$cyan-300: 	#4dd0e1!default;
$cyan-400: 	#26c6da!default;
$cyan-500: 	#00bcd4!default;
$cyan-600: 	#00acc1!default;
$cyan-700: 	#0097a7!default;
$cyan-800: 	#00838f!default;
$cyan-900: 	#006064!default;

$cyan-A100: #84ffff!default;
$cyan-A200: #18ffff!default;
$cyan-A400: #00e5ff!default;
$cyan-A700: #00b8d4!default;

$cyan: $cyan-500!default;



//== Teal
//
$teal-50: 	#e0f2f1!default;
$teal-100: 	#b2dfdb!default;
$teal-200: 	#80cbc4!default;
$teal-300: 	#4db6ac!default;
$teal-400: 	#26a69a!default;
$teal-500: 	#009688!default;
$teal-600: 	#00897b!default;
$teal-700: 	#00796b!default;
$teal-800: 	#00695c!default;
$teal-900: 	#004d40!default;

$teal-A100: #a7ffeb!default;
$teal-A200: #64ffda!default;
$teal-A400: #1de9b6!default;
$teal-A700: #00bfa5!default;

$teal: $teal-500!default;



//== Green
//
$green-50: 		#e8f5e9!default;
$green-100: 	#c8e6c9!default;
$green-200: 	#a5d6a7!default;
$green-300: 	#81c784!default;
$green-400: 	#66bb6a!default;
$green-500: 	#4caf50!default;
$green-600: 	#43a047!default;
$green-700: 	#388e3c!default;
$green-800: 	#2e7d32!default;
$green-900: 	#1b5e20!default;

$green-A100: 	#b9f6ca!default;
$green-A200: 	#69f0ae!default;
$green-A400: 	#00e676!default;
$green-A700: 	#00c853!default;

$green: $green-500!default;



//== Light Green
//
$green-light-50: 		#f1f8e9!default;
$green-light-100: 	#dcedc8!default;
$green-light-200: 	#c5e1a5!default;
$green-light-300: 	#aed581!default;
$green-light-400: 	#9ccc65!default;
$green-light-500: 	#8bc34a!default;
$green-light-600: 	#7cb342!default;
$green-light-700: 	#689f38!default;
$green-light-800: 	#558b2f!default;
$green-light-900: 	#33691e!default;

$green-light-A100: 	#ccff90!default;
$green-light-A200: 	#b2ff59!default;
$green-light-A400: 	#76ff03!default;
$green-light-A700: 	#64dd17!default;

$green-light: $green-light-500!default;



//== Lime
//
$lime-50: 	#f9fbe7!default;
$lime-100: 	#f0f4c3!default;
$lime-200: 	#e6ee9c!default;
$lime-300: 	#dce775!default;
$lime-400: 	#d4e157!default;
$lime-500: 	#cddc39!default;
$lime-600: 	#c0ca33!default;
$lime-700: 	#afb42b!default;
$lime-800: 	#9e9d24!default;
$lime-900: 	#827717!default;

$lime-A100: #f4ff81!default;
$lime-A200: #eeff41!default;
$lime-A400: #c6ff00!default;
$lime-A700: #aeea00!default;

$lime: $lime-500!default;



//== Yellow
//
$yellow-50: 	#fffde7!default;
$yellow-100: 	#fff9c4!default;
$yellow-200: 	#fff59d!default;
$yellow-300: 	#fff176!default;
$yellow-400: 	#ffee58!default;
$yellow-500: 	#ffeb3b!default;
$yellow-600: 	#fdd835!default;
$yellow-700: 	#fbc02d!default;
$yellow-800: 	#f9a825!default;
$yellow-900: 	#f57f17!default;

$yellow-A100: #ffff8d!default;
$yellow-A200: #ffff00!default;
$yellow-A400: #ffea00!default;
$yellow-A700: #ffd600!default;

$yellow: $yellow-500!default;



//== Amber
//
$amber-50: 		#fff8e1!default;
$amber-100: 	#ffecb3!default;
$amber-200: 	#ffe082!default;
$amber-300: 	#ffd54f!default;
$amber-400: 	#ffca28!default;
$amber-500: 	#ffc107!default;
$amber-600: 	#ffb300!default;
$amber-700: 	#ffa000!default;
$amber-800: 	#ff8f00!default;
$amber-900: 	#ff6f00!default;

$amber-A100:	#ffe57f!default;
$amber-A200: 	#ffd740!default;
$amber-A400: 	#ffc400!default;
$amber-A700: 	#ffab00!default;

$amber: $amber-500!default;



//== Orange
//
$orange-50: 	#fff3e0!default;
$orange-100: 	#ffe0b2!default;
$orange-200: 	#ffcc80!default;
$orange-300: 	#ffb74d!default;
$orange-400: 	#ffa726!default;
$orange-500: 	#ff9800!default;
$orange-600: 	#fb8c00!default;
$orange-700: 	#f57c00!default;
$orange-800: 	#ef6c00!default;
$orange-900: 	#e65100!default;

$orange-A100: #ffd180!default;
$orange-A200: #ffab40!default;
$orange-A400: #ff9100!default;
$orange-A700: #ff6d00!default;

$orange: $orange-500!default;



//== Deep Orange
//
$orange-deep-50: 		#fbe9e7!default;
$orange-deep-100: 	#ffccbc!default;
$orange-deep-200: 	#ffab91!default;
$orange-deep-300: 	#ff8a65!default;
$orange-deep-400: 	#ff7043!default;
$orange-deep-500: 	#ff5722!default;
$orange-deep-600: 	#f4511e!default;
$orange-deep-700: 	#e64a19!default;
$orange-deep-800: 	#d84315!default;
$orange-deep-900: 	#bf360c!default;

$orange-deep-A100: 	#ff9e80!default;
$orange-deep-A200: 	#ff6e40!default;
$orange-deep-A400: 	#ff3d00!default;
$orange-deep-A700: 	#dd2c00!default;

$orange-deep: $orange-deep-500!default;



//== Brown
//
$brown-50: 	#efebe9!default;
$brown-100: #d7ccc8!default;
$brown-200: #bcaaa4!default;
$brown-300: #a1887f!default;
$brown-400: #8d6e63!default;
$brown-500: #795548!default;
$brown-600: #6d4c41!default;
$brown-700: #5d4037!default;
$brown-800: #4e342e!default;
$brown-900: #3e2723!default;

$brown: $brown-500!default;



//== Grey
//
$gray-50:  	#fafafa!default;
$gray-100: 	#f5f5f5!default;
$gray-200: 	#eeeeee!default;
$gray-300: 	#e0e0e0!default;
$gray-400: 	#bdbdbd!default;
$gray-500: 	#9e9e9e!default;
$gray-600: 	#757575!default;
$gray-700: 	#616161!default;
$gray-800: 	#424242!default;
$gray-900: 	#212121!default;

$gray: $gray-500!default;



//== Blue Grey
//
$gray-blue-50: 	#eceff1!default;
$gray-blue-100: #cfd8dc!default;
$gray-blue-200: #b0bec5!default;
$gray-blue-300: #90a4ae!default;
$gray-blue-400: #78909c!default;
$gray-blue-500: #607d8b!default;
$gray-blue-600: #546e7a!default;
$gray-blue-700: #455a64!default;
$gray-blue-800: #37474f!default;
$gray-blue-900: #263238!default;

$gray-blue: $gray-blue-500!default;