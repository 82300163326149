div.horizontal-tabs {
}

.horizontal-tabs {
  ul.horizontal-tabs-list {
    display: flex;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid $color--gray-lightest;
    li {
      margin: 0 0 -2px 0;
      padding: 0;
      border-bottom: 4px solid $color--gray-lightest;
      text-transform:uppercase;
      &.selected {
        border-bottom-color: $color--green;
      }
    }

    a {
      display: block;
      padding: 1rem 1.5rem;
      color: $color--gray;
      font-weight: normal;
      text-decoration: none;
    }
  }

  .horizontal-tabs-panes {
    .fieldset-wrapper {
      padding: 1rem 0;
    }
  }

  fieldset.form-wrapper {
    background-color: transparent;
  }
}

fieldset.horizontal-tabs-pane > legend,
fieldset.vertical-tabs-pane fieldset.horizontal-tabs-pane > legend {
  display: none;
}


/**
 * tab content
 */
div.field-group-htabs-wrapper .field-group-format-wrapper {
  clear: both;
  padding: 0 0 0.6em;
}
/*hide*/
.horizontal-tabs .horizontal-tab-hidden {
  display: block;
  position: absolute;
  top: -100000px;
  width: 100%;
}
