.form-wrapper.checkout_completion_message {
  display: flex;
  align-items: center;
  background-color: rgba(#FFF, .2);
  border: 1px solid $color--green-light;
  border-radius: 1rem;
  padding: 2rem;
  margin: 3rem 0;
  font-size: 1.3rem;
  font-weight: 300;
  &:before {
    content: "\f00c";
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    background-color: $color--green-light;
    border: 1px solid $color--green-light;
    border-radius: 50%;
    margin-right: 1.5rem;
    color: $color--green;
    font-size: 2.5rem;
    font-style: normal;
    font-variant: normal;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
