.mg-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.5rem;
  figure {
    flex: 0 1 50%;
    margin: 0 0 1rem 0;
    padding: 0 .5rem;
    @include media-breakpoint-up(lg) {
      flex: 0 1 25%;
    }
    img {
      @include img-fluid;
    }
  }
}
