#commerce-checkout-form-checkout > div {

  .messages {
    display: none;
  }

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto auto;
    grid-gap: 0px $grid-gutter-width;
    align-items: start;
  }

  #edit-cart-contents  {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  #customer-profile-shipping-ajax-wrapper {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  #commerce-shipping-service-ajax-wrapper {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .checkout-buttons {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }

  // Customer Profile Shipping
  //
  .customer_profile_shipping {
    .form-item {
      margin-bottom: 0;
    }

    div.addressfield-container-inline > div.form-item {
      float: none;
      margin-right: 0;
    }

    .form-item-customer-profile-shipping-profile-selection {
      margin-bottom: 1rem;
    }

    // Hide Address 2 field
    .form-item-customer-profile-shipping-commerce-customer-address-und-0-premise {
      display: none;
    }

    // Postal code nad City
    .locality-block {
      display: flex;
      margin-bottom: 1rem;
      > div:not(:last-child) {
        margin-right: 1rem!important;
      }
    }

    .custom-block {
      margin-bottom: 1rem;
    }

    .addressfield-phone-block {
      margin-bottom: 1rem;
    }

    input[type='text'] {
      padding: .3rem .5rem;
    }

    input[type='submit'] {
      width: 100%;
    }
  }
}


// Action wrapper
//
.checkout-buttons {
  .fieldset-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .button-operator {
      display: none;
    }

    .checkout-cancel,
    .checkout-back {
      color: $color--gray-light;
      margin-right: 1rem;
      &:hover {
        color: $red-300;
      }
    }

  }
}
