/*!
 * Bootstrap v4.1.2 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2196f3;
  --indigo: #3f51b5;
  --purple: #9c27b0;
  --pink: #e91e63;
  --red: #f44336;
  --orange: #ff9800;
  --yellow: #ffeb3b;
  --green: #4caf50;
  --teal: #009688;
  --cyan: #00bcd4;
  --white: #fff;
  --gray: #546e7a;
  --gray-dark: #475962;
  --primary: #8BC34A;
  --secondary: #475962;
  --success: #8BC34A;
  --info: #00bcd4;
  --warning: #ffeb3b;
  --danger: #f44336;
  --light: #eceff1;
  --dark: #263238;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #475962;
  text-align: left;
  background-color: #F7F7F7;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #8BC34A;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #649130;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #546e7a;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #263238;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 900;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 900;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 900;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled, .panels-ipe-linkbar {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #546e7a;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F7F7F7;
  border: 1px solid #90a4ae;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #546e7a;
}

.container {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table, table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th, table th,
.table td,
table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #cdd6db;
}

.table thead th, table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #cdd6db;
}

.table tbody + tbody, table tbody + tbody {
  border-top: 2px solid #cdd6db;
}

.table .table, table .table, .table table, table table {
  background-color: #F7F7F7;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #cdd6db;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #cdd6db;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dfeecc;
}

.table-hover .table-primary:hover {
  background-color: #d3e8b9;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d3e8b9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cbd1d3;
}

.table-hover .table-secondary:hover {
  background-color: #bdc5c7;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #bdc5c7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dfeecc;
}

.table-hover .table-success:hover {
  background-color: #d3e8b9;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d3e8b9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8ecf3;
}

.table-hover .table-info:hover {
  background-color: #a2e6ef;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a2e6ef;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff9c8;
}

.table-hover .table-warning:hover {
  background-color: #fff6af;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff6af;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7;
}

.table-hover .table-danger:hover {
  background-color: #fbb3af;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbb3af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafbfb;
}

.table-hover .table-light:hover {
  background-color: #ecf0f0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ecf0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2c6c7;
}

.table-hover .table-dark:hover {
  background-color: #b5babb;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b5babb;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th, table .thead-dark th {
  color: #F7F7F7;
  background-color: #263238;
  border-color: #35464f;
}

.table .thead-light th, table .thead-light th {
  color: #cdd6db;
  background-color: #cdd6db;
  border-color: #cdd6db;
}

.table-dark {
  color: #F7F7F7;
  background-color: #263238;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #35464f;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 0.9375rem;
    margin-bottom: 0;
    margin-left: 0.9375rem;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #263238;
  background-color: #8BC34A;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #263238;
  text-decoration: none;
  background-color: #71a436;
}

.badge-secondary {
  color: #fff;
  background-color: #475962;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #323e44;
}

.badge-success {
  color: #263238;
  background-color: #8BC34A;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #263238;
  text-decoration: none;
  background-color: #71a436;
}

.badge-info {
  color: #fff;
  background-color: #00bcd4;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #008fa1;
}

.badge-warning {
  color: #263238;
  background-color: #ffeb3b;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #263238;
  text-decoration: none;
  background-color: #ffe608;
}

.badge-danger {
  color: #fff;
  background-color: #f44336;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ea1c0d;
}

.badge-light {
  color: #263238;
  background-color: #eceff1;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #263238;
  text-decoration: none;
  background-color: #cfd6db;
}

.badge-dark {
  color: #fff;
  background-color: #263238;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #11171a;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #455a64;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #455a64;
  text-decoration: none;
  background-color: #cfd8dc;
}

.list-group-item-action:active {
  color: #475962;
  background-color: #b0bec5;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #546e7a;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #8BC34A;
  border-color: #8BC34A;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #486526;
  background-color: #dfeecc;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #486526;
  background-color: #d3e8b9;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #486526;
  border-color: #486526;
}

.list-group-item-secondary {
  color: #252e33;
  background-color: #cbd1d3;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #252e33;
  background-color: #bdc5c7;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #252e33;
  border-color: #252e33;
}

.list-group-item-success {
  color: #486526;
  background-color: #dfeecc;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #486526;
  background-color: #d3e8b9;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #486526;
  border-color: #486526;
}

.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #00626e;
  background-color: #a2e6ef;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00626e;
  border-color: #00626e;
}

.list-group-item-warning {
  color: #857a1f;
  background-color: #fff9c8;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #857a1f;
  background-color: #fff6af;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857a1f;
  border-color: #857a1f;
}

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7f231c;
  background-color: #fbb3af;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f231c;
  border-color: #7f231c;
}

.list-group-item-light {
  color: #7b7c7d;
  background-color: #fafbfb;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7b7c7d;
  background-color: #ecf0f0;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7b7c7d;
  border-color: #7b7c7d;
}

.list-group-item-dark {
  color: #141a1d;
  background-color: #c2c6c7;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141a1d;
  background-color: #b5babb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141a1d;
  border-color: #141a1d;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #b0bec5;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #b0bec5;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #8BC34A !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #71a436 !important;
}

.bg-secondary {
  background-color: #475962 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #323e44 !important;
}

.bg-success {
  background-color: #8BC34A !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #71a436 !important;
}

.bg-info {
  background-color: #00bcd4 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008fa1 !important;
}

.bg-warning {
  background-color: #ffeb3b !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe608 !important;
}

.bg-danger {
  background-color: #f44336 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important;
}

.bg-light {
  background-color: #eceff1 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cfd6db !important;
}

.bg-dark {
  background-color: #263238 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #11171a !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #90a4ae !important;
}

.border-top {
  border-top: 1px solid #90a4ae !important;
}

.border-right {
  border-right: 1px solid #90a4ae !important;
}

.border-bottom {
  border-bottom: 1px solid #90a4ae !important;
}

.border-left {
  border-left: 1px solid #90a4ae !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #8BC34A !important;
}

.border-secondary {
  border-color: #475962 !important;
}

.border-success {
  border-color: #8BC34A !important;
}

.border-info {
  border-color: #00bcd4 !important;
}

.border-warning {
  border-color: #ffeb3b !important;
}

.border-danger {
  border-color: #f44336 !important;
}

.border-light {
  border-color: #eceff1 !important;
}

.border-dark {
  border-color: #263238 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 4rem !important;
}

.mt-8,
.my-8 {
  margin-top: 4rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 4rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 4rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 4rem !important;
}

.m-9 {
  margin: 5rem !important;
}

.mt-9,
.my-9 {
  margin-top: 5rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 5rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 5rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 5rem !important;
}

.m-10 {
  margin: 6rem !important;
}

.mt-10,
.my-10 {
  margin-top: 6rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 6rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 6rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 6rem !important;
}

.m-11 {
  margin: 7rem !important;
}

.mt-11,
.my-11 {
  margin-top: 7rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 7rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 7rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 7rem !important;
}

.m-12 {
  margin: 8rem !important;
}

.mt-12,
.my-12 {
  margin-top: 8rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 8rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 8rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 8rem !important;
}

.m-13 {
  margin: 9rem !important;
}

.mt-13,
.my-13 {
  margin-top: 9rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 9rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 9rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 9rem !important;
}

.m-14 {
  margin: 10rem !important;
}

.mt-14,
.my-14 {
  margin-top: 10rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 10rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 10rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 4rem !important;
}

.pt-8,
.py-8 {
  padding-top: 4rem !important;
}

.pr-8,
.px-8 {
  padding-right: 4rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 4rem !important;
}

.pl-8,
.px-8 {
  padding-left: 4rem !important;
}

.p-9 {
  padding: 5rem !important;
}

.pt-9,
.py-9 {
  padding-top: 5rem !important;
}

.pr-9,
.px-9 {
  padding-right: 5rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 5rem !important;
}

.pl-9,
.px-9 {
  padding-left: 5rem !important;
}

.p-10 {
  padding: 6rem !important;
}

.pt-10,
.py-10 {
  padding-top: 6rem !important;
}

.pr-10,
.px-10 {
  padding-right: 6rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 6rem !important;
}

.pl-10,
.px-10 {
  padding-left: 6rem !important;
}

.p-11 {
  padding: 7rem !important;
}

.pt-11,
.py-11 {
  padding-top: 7rem !important;
}

.pr-11,
.px-11 {
  padding-right: 7rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 7rem !important;
}

.pl-11,
.px-11 {
  padding-left: 7rem !important;
}

.p-12 {
  padding: 8rem !important;
}

.pt-12,
.py-12 {
  padding-top: 8rem !important;
}

.pr-12,
.px-12 {
  padding-right: 8rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 8rem !important;
}

.pl-12,
.px-12 {
  padding-left: 8rem !important;
}

.p-13 {
  padding: 9rem !important;
}

.pt-13,
.py-13 {
  padding-top: 9rem !important;
}

.pr-13,
.px-13 {
  padding-right: 9rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 9rem !important;
}

.pl-13,
.px-13 {
  padding-left: 9rem !important;
}

.p-14 {
  padding: 10rem !important;
}

.pt-14,
.py-14 {
  padding-top: 10rem !important;
}

.pr-14,
.px-14 {
  padding-right: 10rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 10rem !important;
}

.pl-14,
.px-14 {
  padding-left: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 4rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 4rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 4rem !important;
  }
  .m-sm-9 {
    margin: 5rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 5rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 5rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 5rem !important;
  }
  .m-sm-10 {
    margin: 6rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 6rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 6rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 6rem !important;
  }
  .m-sm-11 {
    margin: 7rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 7rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 7rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 7rem !important;
  }
  .m-sm-12 {
    margin: 8rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 8rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 8rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 8rem !important;
  }
  .m-sm-13 {
    margin: 9rem !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 9rem !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 9rem !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 9rem !important;
  }
  .m-sm-14 {
    margin: 10rem !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 10rem !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 10rem !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 4rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 4rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 4rem !important;
  }
  .p-sm-9 {
    padding: 5rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 5rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 5rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 5rem !important;
  }
  .p-sm-10 {
    padding: 6rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 6rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 6rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 6rem !important;
  }
  .p-sm-11 {
    padding: 7rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 7rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 7rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 7rem !important;
  }
  .p-sm-12 {
    padding: 8rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 8rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 8rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 8rem !important;
  }
  .p-sm-13 {
    padding: 9rem !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 9rem !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 9rem !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 9rem !important;
  }
  .p-sm-14 {
    padding: 10rem !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 10rem !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 10rem !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 4rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 4rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 4rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 4rem !important;
  }
  .m-md-9 {
    margin: 5rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 5rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 5rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 5rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 5rem !important;
  }
  .m-md-10 {
    margin: 6rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 6rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 6rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 6rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 6rem !important;
  }
  .m-md-11 {
    margin: 7rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 7rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 7rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 7rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 7rem !important;
  }
  .m-md-12 {
    margin: 8rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 8rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 8rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 8rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 8rem !important;
  }
  .m-md-13 {
    margin: 9rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 9rem !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 9rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 9rem !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 9rem !important;
  }
  .m-md-14 {
    margin: 10rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 10rem !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 10rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 10rem !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 4rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 4rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 4rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 4rem !important;
  }
  .p-md-9 {
    padding: 5rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 5rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 5rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 5rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 5rem !important;
  }
  .p-md-10 {
    padding: 6rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 6rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 6rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 6rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 6rem !important;
  }
  .p-md-11 {
    padding: 7rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 7rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 7rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 7rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 7rem !important;
  }
  .p-md-12 {
    padding: 8rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 8rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 8rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 8rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 8rem !important;
  }
  .p-md-13 {
    padding: 9rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 9rem !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 9rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 9rem !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 9rem !important;
  }
  .p-md-14 {
    padding: 10rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 10rem !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 10rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 10rem !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 4rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 4rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 4rem !important;
  }
  .m-lg-9 {
    margin: 5rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 5rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 5rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 5rem !important;
  }
  .m-lg-10 {
    margin: 6rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 6rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 6rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 6rem !important;
  }
  .m-lg-11 {
    margin: 7rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 7rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 7rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 7rem !important;
  }
  .m-lg-12 {
    margin: 8rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 8rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 8rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 8rem !important;
  }
  .m-lg-13 {
    margin: 9rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 9rem !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 9rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 9rem !important;
  }
  .m-lg-14 {
    margin: 10rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 10rem !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 10rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 4rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 4rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 4rem !important;
  }
  .p-lg-9 {
    padding: 5rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 5rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 5rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 5rem !important;
  }
  .p-lg-10 {
    padding: 6rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 6rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 6rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 6rem !important;
  }
  .p-lg-11 {
    padding: 7rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 7rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 7rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 7rem !important;
  }
  .p-lg-12 {
    padding: 8rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 8rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 8rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 8rem !important;
  }
  .p-lg-13 {
    padding: 9rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 9rem !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 9rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 9rem !important;
  }
  .p-lg-14 {
    padding: 10rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 10rem !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 10rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 4rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 4rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 4rem !important;
  }
  .m-xl-9 {
    margin: 5rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 5rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 5rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 5rem !important;
  }
  .m-xl-10 {
    margin: 6rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 6rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 6rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 6rem !important;
  }
  .m-xl-11 {
    margin: 7rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 7rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 7rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 7rem !important;
  }
  .m-xl-12 {
    margin: 8rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 8rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 8rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 8rem !important;
  }
  .m-xl-13 {
    margin: 9rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 9rem !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 9rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 9rem !important;
  }
  .m-xl-14 {
    margin: 10rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 10rem !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 10rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 4rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 4rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 4rem !important;
  }
  .p-xl-9 {
    padding: 5rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 5rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 5rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 5rem !important;
  }
  .p-xl-10 {
    padding: 6rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 6rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 6rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 6rem !important;
  }
  .p-xl-11 {
    padding: 7rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 7rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 7rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 7rem !important;
  }
  .p-xl-12 {
    padding: 8rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 8rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 8rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 8rem !important;
  }
  .p-xl-13 {
    padding: 9rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 9rem !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 9rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 9rem !important;
  }
  .p-xl-14 {
    padding: 10rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 10rem !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 10rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #8BC34A !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #71a436 !important;
}

.text-secondary {
  color: #475962 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #323e44 !important;
}

.text-success {
  color: #8BC34A !important;
}

a.text-success:hover, a.text-success:focus {
  color: #71a436 !important;
}

.text-info {
  color: #00bcd4 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #008fa1 !important;
}

.text-warning {
  color: #ffeb3b !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ffe608 !important;
}

.text-danger {
  color: #f44336 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ea1c0d !important;
}

.text-light {
  color: #eceff1 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cfd6db !important;
}

.text-dark {
  color: #263238 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #11171a !important;
}

.text-body {
  color: #475962 !important;
}

.text-muted {
  color: #546e7a !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

html {
  font-size: .875rem;
}

@media (min-width: 768px) {
  html {
    font-size: 1rem;
  }
}

html, body {
  overflow-x: hidden;
}

a:link, a:hover, a:focus, a:active {
  outline: none;
}

.messages {
  margin: 2rem 0;
  padding: 1rem;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: #b0bec5;
}

.messages.status {
  background: #DCEDC8;
  border-color: #8BC34A;
  color: #407A28;
}

.messages.warning {
  background: #fff9c4;
  border-color: #f9a825;
  color: #f9a825;
}

.messages.error {
  background: #ffebee;
  border-color: #e53935;
  color: #e53935;
}

.zig-zag::after {
  content: "";
  display: block;
  width: 93px;
  height: 12px;
  background-image: url("../images/zig-zag.svg");
  background-repeat: no-repeat;
}

.zig-zag--small::after {
  content: "";
  display: block;
  width: 63px;
  height: 9px;
  background-image: url("../images/zig-zag-small.svg");
  background-repeat: no-repeat;
}

/**
 * @file
 * Generic theme-independent base styles.
 */
/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list */
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

/* Animated throbber */
html.js input.form-autocomplete {
  background-image: url(../../../../../../misc/throbber-inactive.png);
  background-position: 100% center;
  /* LTR */
  background-repeat: no-repeat;
}

html.js input.throbbing {
  background-image: url(../../../../../../misc/throbber-active.gif);
  background-position: 100% center;
  /* LTR */
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js fieldset.collapsed .fieldset-wrapper {
  display: none;
}

fieldset.collapsible {
  position: relative;
}

fieldset.collapsible .fieldset-legend {
  display: block;
}

/**
 * Resizable textareas.
 *
 * @see textarea.js
 */
.resizable-textarea .grippie {
  background: #eee url(../../../../../../misc/grippie.png) no-repeat center 2px;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
body.drag {
  cursor: move;
}

.draggable a.tabledrag-handle {
  cursor: move;
  float: left;
  /* LTR */
  height: 1.7em;
  margin-left: -1em;
  /* LTR */
  overflow: hidden;
  text-decoration: none;
}

a.tabledrag-handle:hover {
  text-decoration: none;
}

a.tabledrag-handle .handle {
  background: url(../../../../../../misc/draggable.png) no-repeat 6px 9px;
  height: 13px;
  margin: -0.4em 0.5em;
  /* LTR */
  padding: 0.42em 0.5em;
  /* LTR */
  width: 13px;
}

a.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

div.indentation {
  float: left;
  /* LTR */
  height: 1.7em;
  margin: -0.4em 0.2em -0.4em -0.4em;
  /* LTR */
  padding: 0.42em 0 0.42em 0.6em;
  /* LTR */
  width: 20px;
}

div.tree-child {
  background: url(../../../../../../misc/tree.png) no-repeat 11px center;
  /* LTR */
}

div.tree-child-last {
  background: url(../../../../../../misc/tree-bottom.png) no-repeat 11px center;
  /* LTR */
}

div.tree-child-horizontal {
  background: url(../../../../../../misc/tree.png) no-repeat -11px center;
}

.tabledrag-toggle-weight-wrapper {
  text-align: right;
  /* LTR */
}

/**
 * TableHeader behavior.
 *
 * @see tableheader.js
 */
table.sticky-header {
  background-color: #fff;
  margin-top: 0;
}

/**
 * Progress behavior.
 *
 * @see progress.js
 */
/* Bar */
.progress .bar {
  background-color: #fff;
  border: 1px solid;
}

.progress .filled {
  background-color: #000;
  height: 1.5em;
  width: 5px;
}

.progress .percentage {
  float: right;
  /* LTR */
}

/* Throbber */
.ajax-progress {
  display: inline-block;
}

.ajax-progress .throbber {
  background: transparent url(../../../../../../misc/throbber-active.gif) no-repeat 0px center;
  float: left;
  /* LTR */
  height: 15px;
  margin: 2px;
  width: 15px;
}

.ajax-progress .message {
  padding-left: 20px;
}

tr .ajax-progress .throbber {
  margin: 0 2px;
}

.ajax-progress-bar {
  width: 16em;
}

/**
 * Inline items.
 */
.container-inline div,
.container-inline label {
  display: inline;
}

/* Fieldset contents always need to be rendered as block. */
.container-inline .fieldset-wrapper {
  display: block;
}

/**
 * Prevent text wrapping.
 */
.nowrap {
  white-space: nowrap;
}

/**
 * For anything you want to hide on page load when JS is enabled, so
 * that you can use the JS to control visibility and avoid flicker.
 */
html.js .js-hide {
  display: none;
}

/**
 * Hide elements from all users.
 *
 * Used for elements which should not be immediately displayed to any user. An
 * example would be a collapsible fieldset that will be expanded with a click
 * from a user. The effect of this class can be toggled with the jQuery show()
 * and hide() functions.
 */
.element-hidden {
  display: none;
}

/**
 * Hide elements visually, but keep them available for screen-readers.
 *
 * Used for information required for screen-reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 * "!important" is used to prevent unintentional overrides.
 */
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

/**
 * The .element-focusable class extends the .element-invisible class to allow
 * the element to be focusable when navigated to via the keyboard.
 */
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

/**
 * Markup free clearing.
 *
 * @see http://perishablepress.com/press/2009/12/06/new-clearfix-hack
 */
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* IE6 */
* html .clearfix {
  height: 1%;
}

/* IE7 */
*:first-child + html .clearfix {
  min-height: 1%;
}

table thead {
  background: #263238;
}

table thead th {
  border-color: #263238;
  color: #eceff1;
  font-weight: normal;
}

table thead th a {
  color: #eceff1;
  text-decoration: underline;
}

table thead th a:hover {
  color: #eceff1;
  text-decoration: none;
}

.views-align-left {
  text-align: left;
}

.views-align-center {
  text-align: center;
}

.views-align-right {
  text-align: right;
}

fieldset.form-wrapper {
  background-color: #FFF;
  margin-bottom: 2rem;
}

fieldset.form-wrapper legend {
  display: block;
  background-color: #cfd8dc;
  color: #546e7a;
  padding: 1rem;
  margin-bottom: 0;
  font-size: .9rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
}

fieldset.form-wrapper .fieldset-wrapper {
  padding: 1.5rem;
  border-top: none;
}

fieldset.form-wrapper .fieldset-wrapper .form-item:last-child {
  margin-bottom: 0;
}

fieldset.collapsible legend {
  padding: 0;
}

fieldset.collapsible legend a {
  background-color: #cfd8dc;
  display: flex;
  padding: 1rem;
  color: #546e7a;
  text-decoration: none;
  transition: background-color .2s;
}

fieldset.collapsible legend a:before {
  display: block;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  margin-right: .5rem;
}

fieldset.collapsible.collapsed a {
  background-color: #cfd8dc;
}

fieldset.collapsible.collapsed a:hover {
  background-color: #eceff1;
}

fieldset.collapsible.collapsed a:before {
  content: "\f107";
}

form > div > .form-wrapper:not(fieldset) {
  background-color: #FFF;
  padding: 2rem;
  margin-bottom: 2rem;
}

.form-item {
  margin-bottom: 1.5rem;
}

.form-item label[for^="edit-"]:not(.option) {
  margin-bottom: .25rem;
  padding: 0;
  color: #8BC34A;
  font-size: .7rem;
  text-transform: uppercase;
}

.form-item .description {
  color: #90a4ae;
  font-size: .8rem;
}

.form-item .form-type-checkbox,
.form-item .form-type-radio {
  margin-bottom: 0;
}

table .form-item:last-child {
  margin-bottom: 0;
}

input[type=text], input[type=password], input[type=color], input[type=date], input[type=datetime-local], input[type=email], input[type=month], input[type=number], input[type=range], input[type=search], input[type=tel], input[type=time], input[type=url], input[type=week], textarea {
  background-color: transparent;
  display: block;
  width: 100%;
  padding: .8rem;
  border: none;
  border: 1px solid #cdd6db;
  outline: none;
  box-sizing: border-box;
  transition: border-color .15s;
  -webkit-appearance: none;
}

input:focus[type=text], input:focus[type=password], input:focus[type=color], input:focus[type=date], input:focus[type=datetime-local], input:focus[type=email], input:focus[type=month], input:focus[type=number], input:focus[type=range], input:focus[type=search], input:focus[type=tel], input:focus[type=time], input:focus[type=url], input:focus[type=week], textarea:focus, input:active[type=text], input:active[type=password], input:active[type=color], input:active[type=date], input:active[type=datetime-local], input:active[type=email], input:active[type=month], input:active[type=number], input:active[type=range], input:active[type=search], input:active[type=tel], input:active[type=time], input:active[type=url], input:active[type=week], textarea:active {
  border-color: #8BC34A;
  outline: none;
}

input[type=text]:focus::placeholder, input[type=password]:focus::placeholder, input[type=color]:focus::placeholder, input[type=date]:focus::placeholder, input[type=datetime-local]:focus::placeholder, input[type=email]:focus::placeholder, input[type=month]:focus::placeholder, input[type=number]:focus::placeholder, input[type=range]:focus::placeholder, input[type=search]:focus::placeholder, input[type=tel]:focus::placeholder, input[type=time]:focus::placeholder, input[type=url]:focus::placeholder, input[type=week]:focus::placeholder, textarea:focus::placeholder, input[type=text]:active::placeholder, input[type=password]:active::placeholder, input[type=color]:active::placeholder, input[type=date]:active::placeholder, input[type=datetime-local]:active::placeholder, input[type=email]:active::placeholder, input[type=month]:active::placeholder, input[type=number]:active::placeholder, input[type=range]:active::placeholder, input[type=search]:active::placeholder, input[type=tel]:active::placeholder, input[type=time]:active::placeholder, input[type=url]:active::placeholder, input[type=week]:active::placeholder, textarea:active::placeholder {
  opacity: .7;
}

input[type=text]::placeholder, input[type=password]::placeholder, input[type=color]::placeholder, input[type=date]::placeholder, input[type=datetime-local]::placeholder, input[type=email]::placeholder, input[type=month]::placeholder, input[type=number]::placeholder, input[type=range]::placeholder, input[type=search]::placeholder, input[type=tel]::placeholder, input[type=time]::placeholder, input[type=url]::placeholder, input[type=week]::placeholder, textarea::placeholder {
  color: #b0bec5;
  transition: opacity .2s;
}

textarea {
  padding: .5rem;
  border: 1px solid #b0bec5;
  font-size: 1rem;
  -webkit-appearance: none;
}

select {
  background-color: transparent;
  background-image: url("../images/caret-down.svg");
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  display: block;
  padding: 0 calc(1rem + 30px) 0 1rem;
  border: 1px solid #eceff1;
  font-size: 1rem;
  outline: none;
  box-sizing: border-box;
  height: 2.5rem;
  color: #475962;
  -webkit-appearance: none;
}

.form-type-password-confirm .form-item {
  margin-bottom: 0;
}

input[type='submit'] {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  background-color: #8BC34A;
  color: #fff;
  padding: .7rem 1.5rem;
  display: inline-block;
}

input[type='submit']:focus {
  outline: none;
}

input[type='submit']:hover {
  background-color: #97c95d;
  color: #fff;
}

input[type='submit']:disabled, input[type='submit'].disabled {
  background-color: #DCEDC8;
  cursor: default;
}

.form-actions.form-wrapper {
  margin-bottom: 0;
}

.form-actions input[type='submit']:not(:last-child) {
  margin-right: .5rem;
}

.button {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.button--default {
  padding: .7rem 1.5rem;
}

.button--large {
  padding: 1rem 2rem;
}

.button--x-large {
  padding: 1.2rem 3rem;
  font-size: 1.2rem;
}

.button--primary {
  background-color: #8BC34A;
  color: #fff;
}

.button--primary:hover {
  background-color: #97c95d;
  color: #fff;
}

.button--primary:disabled, .button--primary.disabled {
  background-color: #DCEDC8;
  cursor: default;
}

.action-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  margin-bottom: 1.5rem;
}

.action-links li {
  margin: 0;
}

.action-links a {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  padding: .7rem 1.5rem;
  background-color: #8BC34A;
  color: #fff;
}

.action-links a:focus {
  outline: none;
}

.action-links a:hover {
  background-color: #97c95d;
  color: #fff;
}

.action-links a:disabled, .action-links a.disabled {
  background-color: #DCEDC8;
  cursor: default;
}

nav.tabs {
  margin: 0 0 2rem;
}

.tabs {
  padding-top: 40px;
  border: 1px solid #eceff1;
  border-radius: .25rem;
  position: relative;
}

@media (min-width: 992px) {
  .tabs {
    padding-top: 0;
    border: none;
    border-radius: 0;
  }
}

ul.primary,
ul.secondary {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn-tabs-toggler {
  background-color: #eceff1;
  display: block;
  width: 20%;
  height: 40px;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 992px) {
  .btn-tabs-toggler {
    display: none;
  }
}

.tabs ul.primary {
  background-color: #eceff1;
  display: block;
  margin-top: -1px;
  border: 0;
  border-radius: .25rem;
  position: static;
  max-height: 0;
  overflow: hidden;
}

.tabs ul.primary.active {
  max-height: 999rem;
}

@media (min-width: 992px) {
  .tabs ul.primary {
    background-color: transparent;
    display: flex;
    max-height: none;
    align-items: baseline;
    padding: 0;
    border: none;
    border-bottom: 1px solid #cfd8dc;
    overflow: visible;
  }
}

.tabs ul.primary > li {
  display: block;
  margin: 0;
}

.tabs ul.primary > li.active {
  width: 80%;
  position: absolute;
  left: 0;
  top: 0;
}

@media (min-width: 992px) {
  .tabs ul.primary > li.active {
    width: auto;
    position: static;
  }
}

.tabs ul.primary > li a {
  display: block;
  background: transparent;
  height: 40px;
  padding: 0 1.5rem;
  border-top: 1px solid #FFF;
  color: #546e7a;
  line-height: 40px;
  text-decoration: none;
  text-align: left;
  transition: all .2s ease-in-out;
}

@media (min-width: 992px) {
  .tabs ul.primary > li a {
    height: auto;
    line-height: 1rem;
    margin-bottom: -2px;
    padding: .5rem 1rem;
    border: none;
    border-bottom: 3px solid transparent;
    text-align: center;
  }
}

.tabs ul.primary > li a:hover {
  color: #263238;
  border-bottom-color: #8BC34A;
}

.tabs ul.primary > li.active a {
  color: #000;
  font-weight: bold;
  border-top: none;
}

@media (min-width: 992px) {
  .tabs ul.primary > li.active a {
    border-bottom-color: #8BC34A;
  }
}

ul.tabs.secondary {
  display: flex;
}

ul.tabs.secondary > li a {
  display: block;
  padding: .5rem 1rem;
  color: #90a4ae;
}

body.node-type-article nav.tabs,
body.node-type-blog-post nav.tabs {
  margin-bottom: 0;
}

body.node-type-article nav.tabs ul.primary,
body.node-type-blog-post nav.tabs ul.primary {
  justify-content: center;
}

@media (min-width: 992px) {
  body.node-type-article nav.tabs ul.primary > li > a,
  body.node-type-blog-post nav.tabs ul.primary > li > a {
    padding: 1rem;
  }
}

main.user-profile nav.tabs {
  margin: 2rem 0 0 0;
}

@media (min-width: 992px) {
  main.user-profile nav.tabs {
    margin: 0 0 1.5rem 0;
  }
}

@media (min-width: 992px) {
  main.user-profile nav.tabs ul.primary {
    margin-left: -9999rem;
    margin-right: -9999rem;
    padding-left: 9999rem;
    padding-right: 9999rem;
    position: static;
    justify-content: space-between;
  }
}

main.user-profile nav.tabs ul.primary > li {
  flex: 1;
}

@media (min-width: 992px) {
  main.user-profile nav.tabs ul.primary > li > a {
    padding: 1rem;
  }
}

.field-type-image img {
  max-width: 100%;
  height: auto;
}

.header {
  position: relative;
  z-index: 10;
}

.header .logo {
  display: inline-block;
  margin: .4rem;
}

.header__top {
  font-size: 0.875rem;
}

.header__top .block-menu ul.menu {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.header__top .block-menu ul.menu > li {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.header__top .block-menu ul.menu a {
  display: block;
  transition: all .15s ease-in-out;
  text-decoration: none;
}

.header__top .block-menu ul.menu a {
  padding: .6rem;
  color: #FFF;
}

.header__top .block-menu ul.menu a:hover {
  background: rgba(71, 89, 98, 0.2);
}

body.logged-in .header__top [href="/user"] {
  background-color: #8BC34A;
}

.ajax-cart__button, .button--hamburger-menu {
  background-color: transparent;
  padding: .5rem 1rem;
  color: #475962;
  position: relative;
}

.ajax-cart__button:hover, .button--hamburger-menu:hover {
  color: #8BC34A;
}

@media (min-width: 992px) {
  .ajax-cart__button, .button--hamburger-menu {
    border-left: 1px solid #b0bec5;
  }
}

.ajax-cart__button i, .button--hamburger-menu i {
  font-size: 2rem;
  line-height: 1;
}

@media (min-width: 992px) {
  .ajax-cart__button i, .button--hamburger-menu i {
    font-size: 1.5rem;
  }
}

.ajax-cart__button .badge, .button--hamburger-menu .badge {
  position: absolute;
  top: 6px;
  right: 5px;
  color: #FFF;
}

.button--hamburger-menu {
  border-left: 1px solid #b0bec5;
}

.region-off-canvas {
  max-height: 0;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.region-off-canvas.open {
  max-height: 50vh;
}

.region-off-canvas .block-system-main-menu ul.menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.region-off-canvas .block-system-main-menu ul.menu li {
  margin: 0;
}

.region-off-canvas .block-system-main-menu ul.menu a, .region-off-canvas .block-system-main-menu ul.menu .nolink {
  display: block;
  padding: .5rem 2rem;
  border-bottom: 1px solid #dce2e5;
  font-size: 1.4rem;
  color: #263238;
  text-decoration: none;
}

.region-off-canvas .block-system-main-menu ul.menu a:hover, .region-off-canvas .block-system-main-menu ul.menu .nolink:hover {
  text-decoration: none;
}

.region-off-canvas .block-system-main-menu ul.menu a.active-trail, .region-off-canvas .block-system-main-menu ul.menu a.active, .region-off-canvas .block-system-main-menu ul.menu .nolink.active-trail, .region-off-canvas .block-system-main-menu ul.menu .nolink.active {
  background-color: #DCEDC8;
  font-weight: bold;
}

.region-off-canvas .block-system-main-menu ul.menu li.expanded ul.menu {
  background-color: #eceff1;
  overflow: hidden;
  max-height: 0;
  transition: all .2s ease-in-out;
}

.region-off-canvas .block-system-main-menu ul.menu li.expanded ul.menu a, .region-off-canvas .block-system-main-menu ul.menu li.expanded ul.menu .nolink {
  border-bottom: 1px solid #dce2e5;
  padding: 1rem 2rem;
  font-size: 1rem;
}

.region-off-canvas .block-system-main-menu ul.menu li.expanded ul.menu a.active-trail, .region-off-canvas .block-system-main-menu ul.menu li.expanded ul.menu .nolink.active-trail {
  background-color: transparent;
  font-weight: bold;
}

.region-off-canvas .block-system-main-menu ul.menu li.expanded ul.menu.open {
  max-height: 600px;
}

.footer {
  display: block;
  font-size: 0.875rem;
}

.footer__main {
  padding: 1rem;
}

.footer__bottom {
  padding: 1rem;
}

.footer__bottom .block-menu ul.menu {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__bottom .block-menu ul.menu > li {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.footer__bottom .block-menu ul.menu a {
  display: block;
  transition: all .15s ease-in-out;
  text-decoration: none;
}

@media (min-width: 992px) {
  .footer__bottom .block-menu ul.menu {
    display: flex;
  }
}

.footer__bottom .block-menu ul.menu a {
  padding: .7rem .3rem;
  border-bottom: 1px solid rgba(176, 190, 197, 0.5);
  color: #b0bec5;
}

@media (min-width: 992px) {
  .footer__bottom .block-menu ul.menu a {
    padding: 0 .3rem;
    border: none;
  }
}

.footer__bottom .block-menu ul.menu a:hover, .footer__bottom .block-menu ul.menu a.active-trail {
  color: #475962;
  text-decoration: underline;
}

.footer__bottom .block-menu ul.menu a.active-trail {
  text-decoration: none;
}

.card-acceptances {
  background-image: url("../images/card-acceptances.svg");
  background-position: center center;
  background-size: cover;
  background-color: #F7F7F7;
  background-blend-mode: luminosity;
  width: 279px;
  height: 32px;
  margin: auto;
}

.pager {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.pager li {
  margin: 0;
}

.pager a, .pager .pager-current {
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: all .1s ease-in-out;
}

.pager a:hover, .pager .pager-current:hover {
  background: rgba(255, 255, 255, 0.7);
}

.pager .pager-current {
  background: #FFF;
  font-weight: bold;
}

.sf-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
}

.sf-menu::after {
  display: block;
  clear: both;
  content: "";
}

.sf-menu li {
  white-space: nowrap;
  transition: background .2s;
}

.sf-menu a, .sf-menu .nolink {
  padding: .75rem 1rem;
  color: #475962;
  text-decoration: none;
  cursor: pointer;
}

.sf-menu > li > a,
.sf-menu > li > .nolink {
  padding: .875rem;
  font-style: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sf-menu ul {
  background: #f2f4f5;
  width: auto !important;
  min-width: 12em;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border-bottom: none;
  font-size: .875rem;
}

.sf-menu ul .nolink, .sf-menu ul a {
  color: #607d8b;
}

.sf-menu ul li {
  border-bottom: 1px solid #eceff1;
}

.sf-menu ul li.last {
  border-bottom: none;
}

.sf-menu .sf-with-ul,
.sf-menu .nolink.sf-with-ul {
  padding-right: 22px;
  position: relative;
}

.sf-menu .sf-with-ul:before,
.sf-menu .nolink.sf-with-ul:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  content: "\f078";
  margin-top: -6px;
  font-size: .6rem;
  line-height: .6rem;
  position: absolute;
  right: 8px;
  top: 50%;
}

.sf-menu span.sf-sub-indicator {
  display: none;
}

.sf-menu li:hover,
.sf-menu li.sfHover {
  background: #f2f4f5;
  transition: none;
  color: #263238;
}

.sf-menu li:hover li:hover,
.sf-menu li:hover li.sfHover,
.sf-menu li.sfHover li:hover,
.sf-menu li.sfHover li.sfHover {
  background: #fff;
}

.sf-menu li:hover ul,
.sf-menu li.sfHover ul {
  top: 100% !important;
}

.sf-menu li:hover ul ul,
.sf-menu li.sfHover ul ul {
  top: 0 !important;
}

.horizontal-tabs ul.horizontal-tabs-list {
  display: flex;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #eceff1;
}

.horizontal-tabs ul.horizontal-tabs-list li {
  margin: 0 0 -2px 0;
  padding: 0;
  border-bottom: 4px solid #eceff1;
  text-transform: uppercase;
}

.horizontal-tabs ul.horizontal-tabs-list li.selected {
  border-bottom-color: #8BC34A;
}

.horizontal-tabs ul.horizontal-tabs-list a {
  display: block;
  padding: 1rem 1.5rem;
  color: #475962;
  font-weight: normal;
  text-decoration: none;
}

.horizontal-tabs .horizontal-tabs-panes .fieldset-wrapper {
  padding: 1rem 0;
}

.horizontal-tabs fieldset.form-wrapper {
  background-color: transparent;
}

fieldset.horizontal-tabs-pane > legend,
fieldset.vertical-tabs-pane fieldset.horizontal-tabs-pane > legend {
  display: none;
}

/**
 * tab content
 */
div.field-group-htabs-wrapper .field-group-format-wrapper {
  clear: both;
  padding: 0 0 0.6em;
}

/*hide*/
.horizontal-tabs .horizontal-tab-hidden {
  display: block;
  position: absolute;
  top: -100000px;
  width: 100%;
}

.shariff ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: none;
  flex: inherit;
}

.shariff ul li {
  margin: 0;
}

.shariff ul li {
  flex: 1;
  margin: 0 .2778rem 0;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.shariff ul li:first-child {
  margin-left: 0;
}

.shariff ul li:last-child {
  margin-right: 0;
}

.shariff a {
  display: block;
  min-width: 6rem;
  padding: .4rem .5556rem;
  border-radius: .25rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  line-height: 1rem;
  white-space: nowrap;
}

.shariff a .share_text {
  display: none;
  font-size: .85rem;
}

@media (min-width: 768px) {
  .shariff a .share_text {
    display: inline-block;
    margin-left: .5rem;
  }
}

.shariff ul.theme-white li.addthis a {
  color: #f8694d;
  border-color: #f8694d;
}

.shariff ul.theme-white li.addthis a:hover {
  background-color: #f8694d;
  color: #FFF;
}

.shariff ul.theme-white li.diaspora a {
  color: #999;
  border-color: #999;
}

.shariff ul.theme-white li.diaspora a:hover {
  background-color: #999;
  color: #FFF;
}

.shariff ul.theme-white li.facebook a {
  color: #3b5998;
  border-color: #3b5998;
}

.shariff ul.theme-white li.facebook a:hover {
  background-color: #3b5998;
  color: #FFF;
}

.shariff ul.theme-white li.flattr a {
  color: #7ea352;
  border-color: #7ea352;
}

.shariff ul.theme-white li.flattr a:hover {
  background-color: #7ea352;
  color: #FFF;
}

.shariff ul.theme-white li.flipboard a {
  color: #e12828;
  border-color: #e12828;
}

.shariff ul.theme-white li.flipboard a:hover {
  background-color: #e12828;
  color: #FFF;
}

.shariff ul.theme-white li.googleplus a {
  color: #d34836;
  border-color: #d34836;
}

.shariff ul.theme-white li.googleplus a:hover {
  background-color: #d34836;
  color: #FFF;
}

.shariff ul.theme-white li.linkedin a {
  color: #0077b5;
  border-color: #0077b5;
}

.shariff ul.theme-white li.linkedin a:hover {
  background-color: #0077b5;
  color: #FFF;
}

.shariff ul.theme-white li.mail a {
  color: #03a9f4;
  border-color: #03a9f4;
}

.shariff ul.theme-white li.mail a:hover {
  background-color: #03a9f4;
  color: #FFF;
}

.shariff ul.theme-white li.pinterest a {
  color: #bd081c;
  border-color: #bd081c;
}

.shariff ul.theme-white li.pinterest a:hover {
  background-color: #bd081c;
  color: #FFF;
}

.shariff ul.theme-white li.print a {
  color: #999;
  border-color: #999;
}

.shariff ul.theme-white li.print a:hover {
  background-color: #999;
  color: #FFF;
}

.shariff ul.theme-white li.qzone a {
  color: #2B82D9;
  border-color: #2B82D9;
}

.shariff ul.theme-white li.qzone a:hover {
  background-color: #2B82D9;
  color: #FFF;
}

.shariff ul.theme-white li.reddit a {
  color: #ff4500;
  border-color: #ff4500;
}

.shariff ul.theme-white li.reddit a:hover {
  background-color: #ff4500;
  color: #FFF;
}

.shariff ul.theme-white li.stumbleupon a {
  color: #eb4924;
  border-color: #eb4924;
}

.shariff ul.theme-white li.stumbleupon a:hover {
  background-color: #eb4924;
  color: #FFF;
}

.shariff ul.theme-white li.telegram a {
  color: #0088cc;
  border-color: #0088cc;
}

.shariff ul.theme-white li.telegram a:hover {
  background-color: #0088cc;
  color: #FFF;
}

.shariff ul.theme-white li.tencent-weibo a {
  color: #26ACE0;
  border-color: #26ACE0;
}

.shariff ul.theme-white li.tencent-weibo a:hover {
  background-color: #26ACE0;
  color: #FFF;
}

.shariff ul.theme-white li.threema a {
  color: #333333;
  border-color: #333333;
}

.shariff ul.theme-white li.threema a:hover {
  background-color: #333333;
  color: #FFF;
}

.shariff ul.theme-white li.tumblr a {
  color: #36465D;
  border-color: #36465D;
}

.shariff ul.theme-white li.tumblr a:hover {
  background-color: #36465D;
  color: #FFF;
}

.shariff ul.theme-white li.twitter a {
  color: #55acee;
  border-color: #55acee;
}

.shariff ul.theme-white li.twitter a:hover {
  background-color: #55acee;
  color: #FFF;
}

.shariff ul.theme-white li.vk a {
  color: #5d7fa4;
  border-color: #5d7fa4;
}

.shariff ul.theme-white li.vk a:hover {
  background-color: #5d7fa4;
  color: #FFF;
}

.shariff ul.theme-white li.weibo a {
  color: #F56770;
  border-color: #F56770;
}

.shariff ul.theme-white li.weibo a:hover {
  background-color: #F56770;
  color: #FFF;
}

.shariff ul.theme-white li.whatsapp a {
  color: #5cbe4a;
  border-color: #5cbe4a;
}

.shariff ul.theme-white li.whatsapp a:hover {
  background-color: #5cbe4a;
  color: #FFF;
}

.shariff ul.theme-white li.xing a {
  color: #126567;
  border-color: #126567;
}

.shariff ul.theme-white li.xing a:hover {
  background-color: #126567;
  color: #FFF;
}

.shariff ul.theme-grey li.addthis a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.addthis a:hover {
  background-color: #f8694d;
  color: #FFF;
  border-color: #f8694d;
}

.shariff ul.theme-grey li.diaspora a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.diaspora a:hover {
  background-color: #999;
  color: #FFF;
  border-color: #999;
}

.shariff ul.theme-grey li.facebook a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.facebook a:hover {
  background-color: #3b5998;
  color: #FFF;
  border-color: #3b5998;
}

.shariff ul.theme-grey li.flattr a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.flattr a:hover {
  background-color: #7ea352;
  color: #FFF;
  border-color: #7ea352;
}

.shariff ul.theme-grey li.flipboard a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.flipboard a:hover {
  background-color: #e12828;
  color: #FFF;
  border-color: #e12828;
}

.shariff ul.theme-grey li.googleplus a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.googleplus a:hover {
  background-color: #d34836;
  color: #FFF;
  border-color: #d34836;
}

.shariff ul.theme-grey li.linkedin a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.linkedin a:hover {
  background-color: #0077b5;
  color: #FFF;
  border-color: #0077b5;
}

.shariff ul.theme-grey li.mail a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.mail a:hover {
  background-color: #03a9f4;
  color: #FFF;
  border-color: #03a9f4;
}

.shariff ul.theme-grey li.pinterest a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.pinterest a:hover {
  background-color: #bd081c;
  color: #FFF;
  border-color: #bd081c;
}

.shariff ul.theme-grey li.print a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.print a:hover {
  background-color: #999;
  color: #FFF;
  border-color: #999;
}

.shariff ul.theme-grey li.qzone a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.qzone a:hover {
  background-color: #2B82D9;
  color: #FFF;
  border-color: #2B82D9;
}

.shariff ul.theme-grey li.reddit a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.reddit a:hover {
  background-color: #ff4500;
  color: #FFF;
  border-color: #ff4500;
}

.shariff ul.theme-grey li.stumbleupon a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.stumbleupon a:hover {
  background-color: #eb4924;
  color: #FFF;
  border-color: #eb4924;
}

.shariff ul.theme-grey li.telegram a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.telegram a:hover {
  background-color: #0088cc;
  color: #FFF;
  border-color: #0088cc;
}

.shariff ul.theme-grey li.tencent-weibo a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.tencent-weibo a:hover {
  background-color: #26ACE0;
  color: #FFF;
  border-color: #26ACE0;
}

.shariff ul.theme-grey li.threema a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.threema a:hover {
  background-color: #333333;
  color: #FFF;
  border-color: #333333;
}

.shariff ul.theme-grey li.tumblr a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.tumblr a:hover {
  background-color: #36465D;
  color: #FFF;
  border-color: #36465D;
}

.shariff ul.theme-grey li.twitter a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.twitter a:hover {
  background-color: #55acee;
  color: #FFF;
  border-color: #55acee;
}

.shariff ul.theme-grey li.vk a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.vk a:hover {
  background-color: #5d7fa4;
  color: #FFF;
  border-color: #5d7fa4;
}

.shariff ul.theme-grey li.weibo a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.weibo a:hover {
  background-color: #F56770;
  color: #FFF;
  border-color: #F56770;
}

.shariff ul.theme-grey li.whatsapp a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.whatsapp a:hover {
  background-color: #5cbe4a;
  color: #FFF;
  border-color: #5cbe4a;
}

.shariff ul.theme-grey li.xing a {
  color: #475962;
  border-color: #475962;
}

.shariff ul.theme-grey li.xing a:hover {
  background-color: #126567;
  color: #FFF;
  border-color: #126567;
}

.shariff ul.theme-colored li.addthis a {
  background-color: #f8694d;
  border: 1px solid #f8694d;
  color: #FFF;
}

.shariff ul.theme-colored li.addthis a:hover {
  background-color: #f8694d;
  border-color: #f8694d;
}

.shariff ul.theme-colored li.diaspora a {
  background-color: #999;
  border: 1px solid #999;
  color: #FFF;
}

.shariff ul.theme-colored li.diaspora a:hover {
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}

.shariff ul.theme-colored li.facebook a {
  background-color: #3b5998;
  border: 1px solid #3b5998;
  color: #FFF;
}

.shariff ul.theme-colored li.facebook a:hover {
  background-color: #4273c8;
  border-color: #4273c8;
}

.shariff ul.theme-colored li.flattr a {
  background-color: #7ea352;
  border: 1px solid #7ea352;
  color: #FFF;
}

.shariff ul.theme-colored li.flattr a:hover {
  background-color: #F67C1A;
  border-color: #F67C1A;
}

.shariff ul.theme-colored li.flipboard a {
  background-color: #e12828;
  border: 1px solid #e12828;
  color: #FFF;
}

.shariff ul.theme-colored li.flipboard a:hover {
  background-color: #ff2e2e;
  border-color: #ff2e2e;
}

.shariff ul.theme-colored li.googleplus a {
  background-color: #d34836;
  border: 1px solid #d34836;
  color: #FFF;
}

.shariff ul.theme-colored li.googleplus a:hover {
  background-color: #f75b44;
  border-color: #f75b44;
}

.shariff ul.theme-colored li.linkedin a {
  background-color: #0077b5;
  border: 1px solid #0077b5;
  color: #FFF;
}

.shariff ul.theme-colored li.linkedin a:hover {
  background-color: #0369a0;
  border-color: #0369a0;
}

.shariff ul.theme-colored li.mail a {
  background-color: #03a9f4;
  border: 1px solid #03a9f4;
  color: #FFF;
}

.shariff ul.theme-colored li.mail a:hover {
  background-color: #4fc3f7;
  border-color: #4fc3f7;
}

.shariff ul.theme-colored li.pinterest a {
  background-color: #bd081c;
  border: 1px solid #bd081c;
  color: #FFF;
}

.shariff ul.theme-colored li.pinterest a:hover {
  background-color: #d50920;
  border-color: #d50920;
}

.shariff ul.theme-colored li.print a {
  background-color: #999;
  border: 1px solid #999;
  color: #FFF;
}

.shariff ul.theme-colored li.print a:hover {
  background-color: #a8a8a8;
  border-color: #a8a8a8;
}

.shariff ul.theme-colored li.qzone a {
  background-color: #2B82D9;
  border: 1px solid #2B82D9;
  color: #FFF;
}

.shariff ul.theme-colored li.qzone a:hover {
  background-color: #398FE6;
  border-color: #398FE6;
}

.shariff ul.theme-colored li.reddit a {
  background-color: #ff4500;
  border: 1px solid #ff4500;
  color: #FFF;
}

.shariff ul.theme-colored li.reddit a:hover {
  background-color: #ff6a33;
  border-color: #ff6a33;
}

.shariff ul.theme-colored li.stumbleupon a {
  background-color: #eb4924;
  border: 1px solid #eb4924;
  color: #FFF;
}

.shariff ul.theme-colored li.stumbleupon a:hover {
  background-color: #ef7053;
  border-color: #ef7053;
}

.shariff ul.theme-colored li.telegram a {
  background-color: #0088cc;
  border: 1px solid #0088cc;
  color: #FFF;
}

.shariff ul.theme-colored li.telegram a:hover {
  background-color: #007dbb;
  border-color: #007dbb;
}

.shariff ul.theme-colored li.tencent-weibo a {
  background-color: #26ACE0;
  border: 1px solid #26ACE0;
  color: #FFF;
}

.shariff ul.theme-colored li.tencent-weibo a:hover {
  background-color: #38BBEB;
  border-color: #38BBEB;
}

.shariff ul.theme-colored li.threema a {
  background-color: #333333;
  border: 1px solid #333333;
  color: #FFF;
}

.shariff ul.theme-colored li.threema a:hover {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.shariff ul.theme-colored li.tumblr a {
  background-color: #36465D;
  border: 1px solid #36465D;
  color: #FFF;
}

.shariff ul.theme-colored li.tumblr a:hover {
  background-color: #44546B;
  border-color: #44546B;
}

.shariff ul.theme-colored li.twitter a {
  background-color: #55acee;
  border: 1px solid #55acee;
  color: #FFF;
}

.shariff ul.theme-colored li.twitter a:hover {
  background-color: #32bbf5;
  border-color: #32bbf5;
}

.shariff ul.theme-colored li.vk a {
  background-color: #5d7fa4;
  border: 1px solid #5d7fa4;
  color: #FFF;
}

.shariff ul.theme-colored li.vk a:hover {
  background-color: #678eb4;
  border-color: #678eb4;
}

.shariff ul.theme-colored li.weibo a {
  background-color: #F56770;
  border: 1px solid #F56770;
  color: #FFF;
}

.shariff ul.theme-colored li.weibo a:hover {
  background-color: #FA7F8A;
  border-color: #FA7F8A;
}

.shariff ul.theme-colored li.whatsapp a {
  background-color: #5cbe4a;
  border: 1px solid #5cbe4a;
  color: #FFF;
}

.shariff ul.theme-colored li.whatsapp a:hover {
  background-color: #34af23;
  border-color: #34af23;
}

.shariff ul.theme-colored li.xing a {
  background-color: #126567;
  border: 1px solid #126567;
  color: #FFF;
}

.shariff ul.theme-colored li.xing a:hover {
  background-color: #29888a;
  border-color: #29888a;
}

.slick--optionset--promo-slider {
  position: relative;
}

.slick--optionset--promo-slider .image-slide {
  height: 30vh;
  position: relative;
}

@media (min-width: 992px) {
  .slick--optionset--promo-slider .image-slide {
    height: 60vh;
  }
}

.slick--optionset--promo-slider .promo-link {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.slick--optionset--promo-slider .slick__arrow {
  margin: 0;
}

.slick--optionset--promo-slider .slick__arrow button {
  background-color: transparent;
  border: none;
  color: #FFF;
  font-size: 2rem;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
}

.slick--optionset--promo-slider .slick__arrow button:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.4s;
  z-index: -1;
}

.slick--optionset--promo-slider .slick__arrow button:hover {
  color: #FFF;
}

.slick--optionset--promo-slider .slick__arrow button:hover:before {
  opacity: 1;
}

.slick--optionset--promo-slider .slick__arrow button:focus {
  outline: 0;
}

.slick--optionset--promo-slider .slick__arrow .slick-prev {
  width: 50px;
}

@media (min-width: 768px) {
  .slick--optionset--promo-slider .slick__arrow .slick-prev {
    width: 80px;
  }
}

.slick--optionset--promo-slider .slick__arrow .slick-next {
  width: 50px;
  right: 0;
}

@media (min-width: 768px) {
  .slick--optionset--promo-slider .slick__arrow .slick-next {
    width: 79px;
  }
}

.slick--optionset--brandscarousel {
  position: relative;
}

.slick--optionset--brandscarousel .slick__arrow {
  margin: 0;
}

.slick--optionset--brandscarousel .slick__arrow button {
  background-color: transparent;
  border: none;
  color: #FFF;
  font-size: 2rem;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
}

.slick--optionset--brandscarousel .slick__arrow button:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.4s;
  z-index: -1;
}

.slick--optionset--brandscarousel .slick__arrow button:hover {
  color: #FFF;
}

.slick--optionset--brandscarousel .slick__arrow button:hover:before {
  opacity: 1;
}

.slick--optionset--brandscarousel .slick__arrow button:focus {
  outline: 0;
}

.slick--optionset--brandscarousel .slick__arrow .slick-prev {
  background-image: linear-gradient(to left, rgba(38, 50, 56, 0), rgba(38, 50, 56, 0.3));
  width: 50px;
}

.slick--optionset--brandscarousel .slick__arrow .slick-prev:before {
  background-image: linear-gradient(to left, rgba(38, 50, 56, 0), rgba(38, 50, 56, 0.2));
}

@media (min-width: 768px) {
  .slick--optionset--brandscarousel .slick__arrow .slick-prev {
    width: 80px;
  }
}

.slick--optionset--brandscarousel .slick__arrow .slick-next {
  background-image: linear-gradient(to right, rgba(38, 50, 56, 0), rgba(38, 50, 56, 0.3));
  width: 50px;
  right: 0;
}

.slick--optionset--brandscarousel .slick__arrow .slick-next:before {
  background-image: linear-gradient(to right, rgba(38, 50, 56, 0), rgba(38, 50, 56, 0.2));
}

@media (min-width: 768px) {
  .slick--optionset--brandscarousel .slick__arrow .slick-next {
    width: 79px;
  }
}

.slick--optionset--two-column-carousel {
  position: relative;
}

.slick--optionset--two-column-carousel .slick__arrow button {
  background: transparent;
  display: flex;
  align-items: center;
  height: 50px;
  border: none;
  color: #C5E1A5;
  font-size: 2rem;
  line-height: 1;
  position: absolute;
  top: calc(50% - 25px);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.slick--optionset--two-column-carousel .slick__arrow button:hover {
  color: #8BC34A;
}

.slick--optionset--two-column-carousel .slick__arrow button:focus {
  outline: 0;
}

.slick--optionset--two-column-carousel .slick__arrow .slick-prev {
  left: -10%;
}

.slick--optionset--two-column-carousel .slick__arrow .slick-next {
  right: -10%;
}

.slick--optionset--two-column-carousel .slick-dots {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.slick--optionset--two-column-carousel .slick-dots li {
  margin: 0;
}

.slick--optionset--two-column-carousel .slick-dots li {
  margin: 0 .3rem;
}

.slick--optionset--two-column-carousel .slick-dots li button {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background: #C5E1A5;
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.slick--optionset--two-column-carousel .slick-dots li.slick-active button {
  background: #8BC34A;
}

.pane-title {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
  color: #263238;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}

.pane-title:after {
  content: "";
  display: block;
  width: 63px;
  height: 9px;
  background-image: url("../images/zig-zag-small.svg");
  background-repeat: no-repeat;
  margin-left: .6rem;
}

body.panels-ipe {
  margin-bottom: 60px !important;
}

div.ctools-modal-content a.close {
  font-size: 1rem;
}

/* Hide the IPE toolbar on print output. */
@media print {
  #panels-ipe-control-container {
    display: none !important;
  }
  body.panels-ipe {
    margin-top: 0 !important;
  }
}

/* Hide the control container when the overlay is open. */
html.overlay-open #panels-ipe-control-container {
  display: none !important;
}

html.overlay-open ody.panels-ipe {
  margin-top: 0 !important;
}

/* Hide empty panes when not editing them. */
.panels-ipe-empty-pane {
  display: none;
}

.panels-ipe-editing div.panels-ipe-portlet-wrapper {
  margin-top: 1rem;
  border: 1px dashed #b0bec5;
  transition: box-shadow #475962;
}

.panels-ipe-editing div.panels-ipe-portlet-wrapper:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.panels-ipe-editing .panels-ipe-sort-container {
  min-height: 40px;
}

.panels-ipe-editing .panels-ipe-sort-container .ui-sortable-helper {
  background: white;
}

.panels-ipe-editing .panels-ipe-sort-container .ui-sortable-placeholder {
  background-color: #FFFF99;
  border: 2px dashed #b0bec5 #475962;
}

.panels-ipe-editing div.panel-pane div.admin-links {
  display: none !important;
}

.panels-ipe-linkbar {
  margin: 0;
}

.panels-ipe-linkbar li {
  display: inline-block;
}

div.panels-ipe-draghandle,
div.panels-ipe-nodraghandle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eceff1 #475962;
  border-bottom: 1px solid #475962;
}

div.panels-ipe-draghandle:after,
div.panels-ipe-nodraghandle:after {
  display: none !important;
}

div.panels-ipe-draghandle span.panels-ipe-draghandle-icon {
  display: block;
  cursor: move;
}

span.panels-ipe-draghandle-icon-inner,
li.add-pane a,
li.style a,
li.css a,
li.delete a,
li.edit a {
  transition: all .2s ease-in-out;
}

span.panels-ipe-draghandle-icon-inner:before,
li.add-pane a:before,
li.style a:before,
li.css a:before,
li.delete a:before,
li.edit a:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
}

span.panels-ipe-draghandle-icon-inner:hover,
li.add-pane a:hover,
li.style a:hover,
li.css a:hover,
li.delete a:hover,
li.edit a:hover {
  background: #FFF;
}

span.panels-ipe-draghandle-icon-inner:before {
  content: "\f0b2";
}

li.add-pane a:before {
  content: "\f067";
}

li.style a:before {
  content: "\f53f";
}

li.css a:before {
  content: "\f121";
}

li.delete a:before {
  content: "\f2ed";
}

li.edit a:before {
  content: "\f044";
}

div.panels-ipe-placeholder {
  border: 1px dashed #b0bec5;
  padding: 1rem;
  color: #475962;
  text-align: left;
  position: relative;
}

div.panels-ipe-placeholder h3 {
  font-weight: normal;
  font-size: 15px;
  width: 75px;
  /* In order to prevent the region title from running into the button, set a width. Initital width only--this will be changed by jQuery */
}

/* Hide editor-state-on elements initially */
.panels-ipe-on {
  display: none;
}

.panels-ipe-editing .panels-ipe-on {
  display: block;
}

/* Show editor-state-off elements initially */
.panels-ipe-off {
  display: block;
}

div.panels-ipe-newblock {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -18px;
  margin-left: -30px;
  z-index: 99;
}

div.panels-ipe-handlebar-wrapper li a,
div.panels-ipe-dragtitle span,
div.panels-ipe-newblock a,
span.panels-ipe-draghandle-icon {
  display: block;
  width: 34px;
  height: 34px;
  border: 1px solid #b0bec5;
  color: #475962;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

div.panels-ipe-handlebar-wrapper li a:hover,
div.panels-ipe-dragtitle span:hover,
div.panels-ipe-newblock a:hover,
span.panels-ipe-draghandle-icon:hover {
  background: #FFF;
}

div.panels-ipe-handlebar-wrapper li a span,
div.panels-ipe-newblock a span {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.panels-ipe-editing .panels-ipe-portlet-content {
  margin: 10px 3px;
  overflow: hidden;
}

.panels-ipe-editing .panels-ipe-region {
  border: transparent dotted 1px;
  margin-bottom: 5px;
}

div.panels-ipe-draghandle {
  border: none;
}

.ui-sortable-placeholder {
  margin: 1rem;
  border: 1px dashed #000;
  visibility: visible !important;
  height: 50px !important;
}

.ui-sortable-placeholder * {
  visibility: hidden;
}

/** ============================================================================
 * Controller form markup
 */
div#panels-ipe-control-container {
  z-index: 99999;
  position: fixed;
  bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5em 0;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 3px 20px #000;
}

.ipe-throbber {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  background-color: rgba(0, 0, 0, 0.7);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin-left: -17px;
  color: #FFF;
  font-size: 1.2rem;
  line-height: 34px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 48.5%;
  z-index: 1001;
}

.ipe-throbber:before {
  content: "\f110";
  text-align: center;
}

/* Hide the drupal system throbber image */
.ipe-throbber .throbber {
  display: none;
}

div.panels-ipe-pseudobutton-container,
div.panels-ipe-control .form-submit {
  cursor: pointer;
  background: #000;
  border: 0;
  border-radius: 3px;
  color: #FFF;
  display: inline-block;
  height: 33px;
  margin: 0 10px;
  transition: background .2s ease-in-out;
}

div.panels-ipe-pseudobutton-container:hover,
div.panels-ipe-control .form-submit:hover {
  background: #8BC34A;
}

div.panels-ipe-control .form-submit {
  padding: 0 15px;
}

div.panels-ipe-pseudobutton-container a {
  display: inline-block;
  height: 33px;
  padding: 0 15px;
  color: #FFF;
  line-height: 33px;
  text-decoration: none;
  text-align: center;
}

div.panels-ipe-button-container {
  margin: 0.3em 0.5em;
  text-align: center;
}

form#panels-ipe-edit-control-form {
  text-align: center;
}

.panels-ipe-dragbar-admin-title {
  font-size: 0.9em;
}

.natusana-section__title {
  margin-bottom: 1.5rem;
  letter-spacing: -.01rem;
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.natusana-section__title:after {
  content: "";
  display: block;
  width: 63px;
  height: 9px;
  background-image: url("../images/zig-zag-small.svg");
  background-repeat: no-repeat;
  margin: .5rem auto 0;
}

@media (min-width: 768px) {
  .natusana-section__title {
    margin-bottom: 4rem;
    font-size: 2.5rem;
  }
  .natusana-section__title:after {
    content: "";
    display: block;
    width: 93px;
    height: 12px;
    background-image: url("../images/zig-zag.svg");
    background-repeat: no-repeat;
    margin: 1.5rem auto 0;
  }
}

.natusana-section--v-padding-small {
  padding: 1.667rem 0;
}

.natusana-section--v-padding-medium {
  padding: 1.667rem 0;
}

@media (min-width: 768px) {
  .natusana-section--v-padding-medium {
    padding: 4rem 0;
  }
}

.natusana-section--v-padding-large {
  padding: 2.5rem 0;
}

@media (min-width: 768px) {
  .natusana-section--v-padding-large {
    padding: 6rem 0;
  }
}

@media (min-width: 992px) {
  .natusana-section--v-padding-large {
    padding: 6rem 0;
  }
}

.region-modal-cart {
  margin: 0 1rem;
}

.ajax-cart {
  width: 100%;
}

@media (min-width: 768px) {
  .ajax-cart {
    width: 600px;
  }
}

.ajax-cart__empty {
  padding: 2rem;
  text-align: center;
}

.ajax-cart__empty__message {
  margin: 1.5rem 0;
  color: #b0bec5;
  font-size: 1.1rem;
  font-weight: bold;
}

.ajax-cart__header {
  background-color: #8BC34A;
  padding: 1rem 2rem;
  color: #fff;
  font-size: 1.125rem;
}

.ajax-cart__content {
  padding: 1rem;
}

@media (min-width: 992px) {
  .ajax-cart__content {
    padding: 1rem 2rem;
  }
}

.ajax-cart__item:not(:last-child) {
  border-bottom: 1px solid #eceff1;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
}

.ajax-cart__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #C5E1A5;
}

.ajax-cart__total {
  margin-right: 1rem;
}

.dc-ajax-add-cart-checkout-cart,
.dc-ajax-add-cart-checkout-link {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  padding: 1rem 2rem;
  background-color: #8BC34A;
  color: #fff;
  border-radius: 0;
}

.dc-ajax-add-cart-checkout-cart:focus,
.dc-ajax-add-cart-checkout-link:focus {
  outline: none;
}

.dc-ajax-add-cart-checkout-cart:hover,
.dc-ajax-add-cart-checkout-link:hover {
  background-color: #97c95d;
  color: #fff;
}

.dc-ajax-add-cart-checkout-cart:disabled, .dc-ajax-add-cart-checkout-cart.disabled,
.dc-ajax-add-cart-checkout-link:disabled,
.dc-ajax-add-cart-checkout-link.disabled {
  background-color: #DCEDC8;
  cursor: default;
}

.product-ajax-cart {
  display: flex;
  align-items: center;
}

.product-ajax-cart__image {
  width: 120px;
  margin-right: 1rem;
}

@media (max-width: 991.98px) {
  .product-ajax-cart__image {
    width: auto;
  }
}

.product-ajax-cart__image img {
  max-width: 100%;
  height: auto;
}

.product-ajax-cart__title {
  color: #263238;
  font-weight: bold;
}

.product-ajax-cart .price__amount {
  margin-right: .2rem;
}

.add-cart-message {
  background: #FFF;
  padding: 2rem;
}

.add-cart-message__content {
  margin-bottom: 1.5rem;
}

.add-cart-message__content:before {
  content: "\f291";
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  margin: 0 auto 1.2rem;
  background-color: #C5E1A5;
  border: 1px solid #C5E1A5;
  border-radius: 50%;
  color: #8BC34A;
  font-size: 2.5rem;
  font-style: normal;
  font-variant: normal;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.add-cart-message__description {
  margin-bottom: .2;
  color: #b0bec5;
  font-size: 80%;
}

.add-cart-message__product-title {
  color: #263238;
  font-size: 1.5rem;
}

.add-cart-message__checkout-button {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  background-color: #8BC34A;
  color: #fff;
  padding: .7rem 1.5rem;
}

.add-cart-message__checkout-button:focus {
  outline: none;
}

.add-cart-message__checkout-button:hover {
  background-color: #97c95d;
  color: #fff;
}

.add-cart-message__checkout-button:disabled, .add-cart-message__checkout-button.disabled {
  background-color: #DCEDC8;
  cursor: default;
}

.add-cart-message__continue-shopping {
  margin-top: .5rem;
  color: #b0bec5;
  font-size: 80%;
}

.add-cart-message .price__amount {
  margin-right: .1rem;
}

.page-cart .form-actions {
  padding-right: 0;
}

.view-commerce-cart-form {
  overflow: auto;
}

.view-commerce-cart-form table td {
  vertical-align: middle;
}

.view-commerce-cart-form .views-field-edit-quantity > div {
  display: flex;
  align-items: center;
}

.view-commerce-cart-form .views-field-edit-quantity input {
  width: auto;
  padding: .2rem;
  text-align: center;
}

.view-commerce-cart-form .views-field-edit-quantity a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.view-commerce-cart-form input.delete-line-item {
  background: transparent;
  border: none;
  color: #e57373;
  cursor: pointer;
}

.view-commerce-cart-form input.delete-line-item:hover {
  color: #f44336;
}

.view-commerce-cart-form input.delete-line-item:focus {
  outline: none;
}

.view-commerce-cart-form .line-item-summary {
  display: flex;
  justify-content: flex-end;
}

.view-commerce-cart-form .line-item-summary .line-item-total {
  width: 200px;
  border-top: 2px solid #475962;
  color: #263238;
  font-size: 1.2rem;
}

.view-commerce-cart-form .line-item-summary .price__amount {
  font-size: 1.5rem;
}

#commerce-checkout-form-checkout > div .messages {
  display: none;
}

@media (min-width: 768px) {
  #commerce-checkout-form-checkout > div {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto auto;
    grid-gap: 0px 1.875rem;
    align-items: start;
  }
}

#commerce-checkout-form-checkout > div #edit-cart-contents {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

#commerce-checkout-form-checkout > div #customer-profile-shipping-ajax-wrapper {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

#commerce-checkout-form-checkout > div #commerce-shipping-service-ajax-wrapper {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

#commerce-checkout-form-checkout > div .checkout-buttons {
  grid-column: 1 / 3;
  grid-row: 4 / 5;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping .form-item {
  margin-bottom: 0;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping div.addressfield-container-inline > div.form-item {
  float: none;
  margin-right: 0;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping .form-item-customer-profile-shipping-profile-selection {
  margin-bottom: 1rem;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping .form-item-customer-profile-shipping-commerce-customer-address-und-0-premise {
  display: none;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping .locality-block {
  display: flex;
  margin-bottom: 1rem;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping .locality-block > div:not(:last-child) {
  margin-right: 1rem !important;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping .custom-block {
  margin-bottom: 1rem;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping .addressfield-phone-block {
  margin-bottom: 1rem;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping input[type='text'] {
  padding: .3rem .5rem;
}

#commerce-checkout-form-checkout > div .customer_profile_shipping input[type='submit'] {
  width: 100%;
}

.checkout-buttons .fieldset-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.checkout-buttons .fieldset-wrapper .button-operator {
  display: none;
}

.checkout-buttons .fieldset-wrapper .checkout-cancel,
.checkout-buttons .fieldset-wrapper .checkout-back {
  color: #b0bec5;
  margin-right: 1rem;
}

.checkout-buttons .fieldset-wrapper .checkout-cancel:hover,
.checkout-buttons .fieldset-wrapper .checkout-back:hover {
  color: #e57373;
}

#commerce-checkout-form-review .checkout-help {
  display: none;
}

.checkout_review table .form-item {
  margin-bottom: 0;
}

.checkout_review table .form-item label {
  margin: 0 .5rem 0 0;
  color: #b0bec5;
}

.checkout_review table .form-item label:after {
  content: ":";
}

.checkout_review table tr.pane-title {
  margin: 0;
  border: none;
  font-size: 1rem;
}

.checkout_review table tr.pane-title:after {
  display: none;
}

.checkout_review table tr.pane-title td {
  border: none;
}

.checkout_review table tr.pane-data td {
  border: none;
}

ol.commerce-checkout-progress {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 0 1.5rem 0;
  font-size: .8rem;
}

ol.commerce-checkout-progress li {
  margin: 0;
}

ol.commerce-checkout-progress li {
  width: auto !important;
  float: none !important;
  color: #b0bec5;
  font-weight: normal;
  white-space: nowrap;
}

ol.commerce-checkout-progress li:after {
  content: "/";
  display: inline-block;
  margin: 0 .5rem;
}

ol.commerce-checkout-progress li.active {
  color: #8BC34A;
  font-weight: bold;
}

.form-wrapper.checkout_completion_message {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #C5E1A5;
  border-radius: 1rem;
  padding: 2rem;
  margin: 3rem 0;
  font-size: 1.3rem;
  font-weight: 300;
}

.form-wrapper.checkout_completion_message:before {
  content: "\f00c";
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  background-color: #C5E1A5;
  border: 1px solid #C5E1A5;
  border-radius: 50%;
  margin-right: 1.5rem;
  color: #8BC34A;
  font-size: 2.5rem;
  font-style: normal;
  font-variant: normal;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.form-wrapper.checkout_completion_message p:last-child {
  margin-bottom: 0;
}

.scc-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.scc-list li {
  margin: 0;
}

.scc-list > li:not(:last-child) {
  margin-bottom: .8rem;
  padding-bottom: .8rem;
  border-bottom: 2px solid #eceff1;
}

.scc-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scc-item__product {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.scc-item__product__image {
  display: none;
}

.scc-item__product__image img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .scc-item__product__image {
    display: block;
    margin-right: 1rem;
  }
}

.scc-item__product__title {
  font-weight: bold;
}

.scc-item .price__amount {
  margin-right: .2rem;
}

.scc-item__total {
  flex: 0 0 auto;
  text-align: right;
  padding-left: 1rem;
}

.scc-item__price {
  color: #b0bec5;
}

.scc-item__total .price__amount {
  font-size: 1.5rem;
}

.field-name-commerce-order-total {
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
  padding-top: .5rem;
  text-align: right;
  border-top: 1px solid #eceff1;
  color: #b0bec5;
}

.field-name-commerce-order-total .price__amount {
  margin-right: .2rem;
}

.field-name-commerce-order-total table, .field-name-commerce-order-total table.table, .field-name-commerce-order-total table, .field-name-commerce-order-total table.table.table-striped, .field-name-commerce-order-total table.table-striped {
  background: transparent;
  width: auto;
}

.field-name-commerce-order-total table tr, .field-name-commerce-order-total table.table tr, .field-name-commerce-order-total table tr, .field-name-commerce-order-total table.table.table-striped tr, .field-name-commerce-order-total table.table-striped tr {
  background: transparent !important;
}

.field-name-commerce-order-total table th, .field-name-commerce-order-total table td, .field-name-commerce-order-total table.table th, .field-name-commerce-order-total table th, .field-name-commerce-order-total table.table td, .field-name-commerce-order-total table td, .field-name-commerce-order-total table.table.table-striped th, .field-name-commerce-order-total table.table-striped th, .field-name-commerce-order-total table.table.table-striped td, .field-name-commerce-order-total table.table-striped td {
  background: transparent;
  padding: .5rem;
  border-top: 1px solid #eceff1;
  vertical-align: middle;
}

.field-name-commerce-order-total table tr:first-child td, .field-name-commerce-order-total table.table tr:first-child td, .field-name-commerce-order-total table tr:first-child td, .field-name-commerce-order-total table.table.table-striped tr:first-child td, .field-name-commerce-order-total table.table-striped tr:first-child td {
  border-top: none;
}

.field-name-commerce-order-total table tr.component-type-commerce-price-formatted-amount, .field-name-commerce-order-total table.table tr.component-type-commerce-price-formatted-amount, .field-name-commerce-order-total table tr.component-type-commerce-price-formatted-amount, .field-name-commerce-order-total table.table.table-striped tr.component-type-commerce-price-formatted-amount, .field-name-commerce-order-total table.table-striped tr.component-type-commerce-price-formatted-amount {
  color: #263238;
}

.field-name-commerce-order-total table tr.component-type-commerce-price-formatted-amount td, .field-name-commerce-order-total table.table tr.component-type-commerce-price-formatted-amount td, .field-name-commerce-order-total table tr.component-type-commerce-price-formatted-amount td, .field-name-commerce-order-total table.table.table-striped tr.component-type-commerce-price-formatted-amount td, .field-name-commerce-order-total table.table-striped tr.component-type-commerce-price-formatted-amount td {
  border-top: 2px solid #475962;
  font-size: 1.2rem;
}

.field-name-commerce-order-total table tr.component-type-commerce-price-formatted-amount .price__amount, .field-name-commerce-order-total table.table tr.component-type-commerce-price-formatted-amount .price__amount, .field-name-commerce-order-total table tr.component-type-commerce-price-formatted-amount .price__amount, .field-name-commerce-order-total table.table.table-striped tr.component-type-commerce-price-formatted-amount .price__amount, .field-name-commerce-order-total table.table-striped tr.component-type-commerce-price-formatted-amount .price__amount {
  font-size: 1.5rem;
}

.field-name-commerce-order-total table td.component-total, .field-name-commerce-order-total table.table td.component-total, .field-name-commerce-order-total table td.component-total, .field-name-commerce-order-total table.table.table-striped td.component-total, .field-name-commerce-order-total table.table-striped td.component-total {
  text-align: right;
}

.term-header {
  background-color: #FFF;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 1.5rem 0;
  border-bottom: 1px solid #eceff1;
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .term-header {
    background-size: auto;
  }
}

@media (min-width: 992px) {
  .term-header {
    padding: 2.5rem 0;
  }
}

.term-header .field-name-field-brand-logo {
  width: 100px;
}

.term-header .field-name-field-brand-logo img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .term-header .field-name-field-brand-logo {
    width: 150px;
  }
}

@media (min-width: 992px) {
  .term-header .field-name-field-brand-logo {
    width: auto;
  }
}

.term-header .taxonomy-term-description {
  display: none;
  color: #b0bec5;
  font-size: 0.875rem;
}

@media (min-width: 992px) {
  .term-header .taxonomy-term-description {
    display: block;
    margin: .2rem 0 0;
  }
}

@media (min-width: 992px) {
  .term-header .taxonomy-term-description {
    margin: .5rem 0 0;
  }
}

.term-header .taxonomy-term-description p:last-child {
  margin-bottom: 0;
}

.brand-teaser__media img {
  max-width: 100%;
  height: auto;
}

.brand-teaser__content {
  padding: 2rem;
}

.brand-teaser__logo {
  margin-bottom: 1rem;
}

.brand-teaser__description {
  color: #b0bec5;
}

@media (min-width: 768px) {
  .brand-teaser__description {
    font-size: .875rem;
  }
}

.brand-teaser a {
  display: block;
  text-decoration: none;
  transition: all .2s ease-in-out;
}

.brand-teaser a:hover {
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.1);
  background-color: #FFF;
}

.product-teaser {
  background-color: #FFF;
  transition: all .2s ease-in-out;
}

.product-teaser:hover {
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.1);
}

.product-teaser__image {
  max-width: 100%;
  height: auto;
  text-align: center;
}

.product-teaser__content {
  text-align: center;
  padding: 2rem 1rem;
}

.product-teaser__content:before {
  content: "";
  display: block;
  width: 63px;
  height: 9px;
  background-image: url("../images/zig-zag-small.svg");
  background-repeat: no-repeat;
  margin: -1rem auto 1rem;
}

.product-teaser__category {
  margin-bottom: .2rem;
  font-size: .875rem;
  color: #b0bec5;
}

.product-teaser__title {
  color: #263238;
  font-size: 1.125rem;
  font-weight: 500;
}

.product-teaser .rrp {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: 1;
}

.product-teaser .rrp__original {
  margin-right: .3rem;
  color: #b0bec5;
  font-size: .875rem;
  text-decoration: line-through;
}

.product-teaser .rrp__sale {
  font-size: 1.125rem;
}

.product-teaser .commerce-add-to-cart {
  padding: 0 0 2rem;
}

.product-teaser .commerce-add-to-cart .form-wrapper {
  margin: 0;
  padding: 0;
}

.product-teaser .commerce-add-to-cart .form-wrapper:empty {
  display: none;
}

.product-teaser .form-type-select {
  display: none;
  position: fixed;
  margin-top: -99999rem;
}

.product-teaser input[type='submit'] {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  padding: .5rem .7rem;
  font-size: .875rem;
  background-color: #8BC34A;
  color: #fff;
  margin: auto;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
}

.product-teaser input[type='submit']:focus {
  outline: none;
}

.product-teaser input[type='submit']:hover {
  background-color: #97c95d;
  color: #fff;
}

.product-teaser input[type='submit']:disabled, .product-teaser input[type='submit'].disabled {
  background-color: #DCEDC8;
  cursor: default;
}

.product-teaser a {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .products-grid [class*="grid--"] > ul > li {
    margin-top: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.product .field-name-field-product-image {
  margin-top: -.6rem;
}

@media (min-width: 768px) {
  .product .field-name-field-product-image {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.product .field-name-field-product-type {
  color: #b0bec5;
  margin-bottom: .3rem;
}

.product .field-name-field-product-type a {
  color: #b0bec5;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.product .field-name-field-product-type a:hover {
  color: #8BC34A;
}

.product .product__header {
  border-bottom: 1px solid #eceff1;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .product .field-name-field-product-short-description {
    font-size: .875rem;
  }
}

.product .rrp {
  display: flex;
  align-items: flex-end;
}

.product .rrp__original {
  color: #b0bec5;
  text-decoration: line-through;
  margin-right: .25rem;
}

.product .rrp__original .price__amount {
  font-weight: normal;
  margin-right: .1rem;
}

.product .rrp__sale .price__amount {
  margin-right: .2rem;
  color: #263238;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
}

.product .commerce-add-to-cart {
  background-color: #f5f6f7;
  margin-top: 2rem;
  padding: 1.5rem;
}

.product .commerce-add-to-cart input, .product .commerce-add-to-cart select {
  background-color: #FFF;
}

.product .commerce-add-to-cart .form-item {
  margin: 0;
}

.product .commerce-add-to-cart .form-wrapper {
  margin: 0;
  padding: 0;
}

.product .commerce-add-to-cart .form-wrapper:empty {
  display: none;
}

.product .commerce-add-to-cart > div {
  display: flex;
  flex-wrap: wrap;
}

.product .commerce-add-to-cart .form-type-select {
  flex: 0 0 100%;
  margin-bottom: 1rem;
}

.product .commerce-add-to-cart .form-type-select select {
  width: 100%;
}

.product .commerce-add-to-cart .form-item-quantity {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.product .commerce-add-to-cart .form-item-quantity label {
  display: none;
}

.product .commerce-add-to-cart .form-item-quantity input {
  width: 3rem;
  margin: 0 .25rem;
  padding: .8rem 0;
  border: none;
  font-size: 1rem;
  text-align: center;
}

.product .commerce-add-to-cart input[type='submit'] {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  padding: 1rem 2rem;
  background-color: #8BC34A;
  color: #fff;
  flex: 1;
}

.product .commerce-add-to-cart input[type='submit']:focus {
  outline: none;
}

.product .commerce-add-to-cart input[type='submit']:hover {
  background-color: #97c95d;
  color: #fff;
}

.product .commerce-add-to-cart input[type='submit']:disabled, .product .commerce-add-to-cart input[type='submit'].disabled {
  background-color: #DCEDC8;
  cursor: default;
}

.teaser {
  position: relative;
}

.teaser__media .teaser__channel {
  position: absolute;
  left: 1.667rem;
  bottom: 0;
}

.teaser__image {
  position: relative;
}

.teaser__image img {
  width: 100%;
  height: auto;
}

.teaser__image a:before {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  z-index: 1;
  transition: opacity .2s ease-in-out;
  box-shadow: inset 0 0 4rem 0 rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.teaser__image.image--circular a:before {
  border-radius: 50%;
  box-shadow: inset 0 0 2rem 0 rgba(0, 0, 0, 0.5);
}

.teaser__image a:hover:before {
  opacity: 1;
}

.teaser__media-icons {
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  position: absolute;
  left: .5556rem;
  top: .5556rem;
  z-index: 5;
  opacity: .7;
}

.teaser__media-icons svg {
  margin-right: .3rem;
}

.teaser__content {
  position: relative;
}

@media (min-width: 992px) {
  .teaser__content {
    z-index: 2;
  }
}

.teaser__channel {
  margin-top: .5rem;
  margin-bottom: .1rem;
  color: #b0bec5;
}

.teaser__channel a {
  color: #b0bec5;
}

.teaser__channel a:hover {
  color: #8BC34A;
}

.teaser__channel--light {
  color: rgba(255, 255, 255, 0.8);
}

.teaser__channel--light a {
  color: rgba(255, 255, 255, 0.8);
}

.teaser__headline {
  margin: 0;
  line-height: 1.1;
}

.teaser__headline a {
  color: #8BC34A;
  text-decoration: none;
  transition: background-color .1s ease-in-out, color .2s ease-in-out;
}

.teaser__headline--medium {
  font-size: 2.5556rem;
}

@media (min-width: 768px) {
  .teaser__headline--medium {
    font-size: 2.8rem;
  }
}

@media (min-width: 992px) {
  .teaser__headline--medium {
    font-size: 3.1rem;
  }
}

@media screen and (min-width: 992px) and (max-height: 700px) {
  .teaser__headline--medium {
    font-size: 2.8rem;
  }
}

.teaser__headline--large {
  font-size: 2.5556rem;
}

@media (min-width: 768px) {
  .teaser__headline--large {
    font-size: 3.1111rem;
  }
}

@media (min-width: 992px) {
  .teaser__headline--large {
    font-size: 3.5rem;
  }
}

@media screen and (min-width: 992px) and (max-height: 700px) {
  .teaser__headline--large {
    font-size: 3.1111rem;
  }
}

.teaser__headline--light,
.teaser__headline--light a {
  color: #fff;
}

.teaser__authored {
  margin-top: .6667rem;
  color: #b0bec5;
  font-size: .75rem;
  line-height: 1;
  font-weight: normal;
}

.teaser__authored a {
  color: #475962;
  text-decoration: underline;
}

.teaser__authored a:hover {
  color: #263238;
  text-decoration: none;
}

.teaser__authored--light {
  color: rgba(255, 255, 255, 0.8);
}

.teaser__authored--light a {
  color: #fff !important;
}

.teaser__body {
  margin-top: .6667rem;
  color: rgba(71, 89, 98, 0.8);
  font-size: 1rem;
}

.teaser__body--light {
  color: rgba(255, 255, 255, 0.8);
}

.teaser__body--light a {
  color: #FFF;
  text-decoration: underline;
}

.teaser .node__links {
  border-top: 1px solid #b0bec5;
  margin-top: .5rem;
  padding-top: .5rem;
  font-size: .7778rem;
}

.teaser .node__links ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.teaser .node__links ul li {
  margin: 0;
}

.teaser .node__links ul li:not(:first-child) {
  margin-left: .7778rem;
}

.teaser__link a {
  display: inline-block;
  margin-top: .7778rem;
  vertical-align: middle;
  font-size: .6111rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.teaser__link-overlay {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all .2s ease-in-out;
  z-index: 5;
}

.teaser__link-overlay:hover {
  box-shadow: inset 0 0 4rem 0 rgba(0, 0, 0, 0.7);
}

.teaser--bordered {
  border: #eceff1;
}

[data-overlay]:before {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 0;
}

.teaser--card.teaser--bordered {
  padding-bottom: 1.5rem;
}

.teaser--card .teaser__headline a:hover {
  color: #C5E1A5;
}

.teaser--card .teaser__content {
  padding: 1rem 1.5rem 0;
}

.teaser--small-card.teaser--bordered {
  padding-bottom: 1rem;
}

.teaser--small-card .teaser__headline {
  font-size: 1.222rem;
}

.teaser--small-card .teaser__content {
  padding: 1rem 1rem 0;
}

.teaser--medium-card .teaser__headline {
  font-size: 1.6667rem;
}

@media (min-width: 992px) {
  .teaser--medium-card .teaser__headline {
    font-size: 1.4rem;
  }
}

@media (min-width: 992px) {
  .teaser--medium-card-square-image .teaser__headline {
    font-size: 1.7rem;
  }
}

@media (min-width: 768px) {
  .teaser--large-card .teaser__headline {
    font-size: 2.5556rem;
  }
}

@media (min-width: 768px) {
  .teaser--large-card .teaser__content {
    padding-top: 1.5rem;
    text-align: center;
  }
}

.teaser--large-card .teaser__body {
  display: none;
}

@media (min-width: 768px) {
  .teaser--large-card .teaser__body {
    display: block;
    margin-top: .6rem;
    padding-left: 4rem;
    padding-right: 4rem;
    line-height: 1.2rem;
  }
}

.teaser--item {
  display: flex;
}

.teaser--item .teaser__media {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 160px;
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .teaser--item .teaser__media {
    flex-basis: auto;
  }
}

.teaser--item .teaser__headline a:hover {
  color: #C5E1A5;
}

.teaser--item .teaser__content {
  flex-grow: 1;
  flex-shrink: 1;
}

.teaser--tiny-item {
  align-items: center;
}

.teaser--tiny-item .teaser__channel {
  font-size: .6667rem;
}

.teaser--tiny-item .teaser__headline {
  font-size: 1.1111rem;
}

@media (min-width: 768px) {
  .teaser--tiny-item .teaser__headline {
    font-size: 1rem;
  }
}

.teaser--tiny-item .teaser__authored {
  margin-top: .5rem;
}

@media (min-width: 992px) {
  .teaser--small-item .teaser__channel {
    font-size: .8889rem;
  }
}

.teaser--small-item .teaser__headline {
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .teaser--small-item .teaser__headline {
    font-size: 1.222rem;
  }
}

.teaser--medium-item {
  display: block;
}

@media (min-width: 768px) {
  .teaser--medium-item {
    display: flex;
  }
}

.teaser--medium-item .teaser__content {
  padding: 1rem 1.5rem 0;
}

@media (min-width: 768px) {
  .teaser--medium-item .teaser__content {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .teaser--medium-item .teaser__headline {
    font-size: 1.4444rem;
  }
}

@media (min-width: 768px) {
  .teaser--medium-item .teaser__body {
    margin-top: .5rem;
  }
}

.teaser--compact .teaser__content {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  padding: 1.5rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.teaser--compact .teaser__channel {
  color: #fff;
}

.teaser--compact .teaser__channel a {
  color: #fff;
}

.teaser--compact .teaser__headline {
  color: #fff;
}

.teaser--compact .teaser__headline a {
  color: #fff;
}

.teaser--compact .teaser__authored {
  color: #b0bec5;
}

.teaser--compact .teaser__authored a {
  color: rgba(176, 190, 197, 0.8);
}

.teaser--small-compact .teaser__headline {
  font-size: 1.222rem;
}

.teaser--small-compact .teaser__content {
  padding: 1rem;
}

@media (min-width: 992px) {
  .teaser--medium-compact .teaser__headline {
    font-size: 1.6667rem;
  }
}

@media (min-width: 768px) {
  .teaser--large-compact .teaser__content {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .teaser--large-compact .teaser__headline {
    font-size: 2.444rem;
    line-height: 1;
  }
}

.teaser--large-compact .teaser__body {
  display: none;
  color: #b0bec5;
}

@media (min-width: 768px) {
  .teaser--large-compact .teaser__body {
    display: block;
    margin-top: .6rem;
    padding-left: 4rem;
    padding-right: 4rem;
    line-height: 1.2rem;
  }
}

.teaser--card.teaser--blog {
  background-color: #FFF;
  transition: all .2s ease-in-out;
}

.teaser--card.teaser--blog .teaser__image a::before {
  display: none;
}

.teaser--card.teaser--blog .teaser__channel {
  margin-bottom: .5rem;
}

.teaser--card.teaser--blog .teaser__content {
  padding: 1.5rem 1.5rem;
}

.teaser--card.teaser--blog:hover {
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.1);
}

.testimonial__title {
  margin-bottom: 1rem;
  color: #263238;
  font-weight: bold;
}

.testimonial__content {
  margin-bottom: 2rem;
  color: #b0bec5;
}

.testimonial__person {
  display: flex;
  align-items: center;
}

.testimonial__person__image {
  margin-right: .5rem;
}

.testimonial__person__image img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.testimonial__person__content {
  color: #b0bec5;
}

.testimonial__person__content .name {
  color: #263238;
  font-weight: bold;
}

#search-api-page-search-form-product-search > div {
  display: flex;
  flex-wrap: wrap;
}

#search-api-page-search-form-product-search > div .form-item {
  background-color: #FFF;
  border: 1px solid #C5E1A5;
  flex: 1;
  margin: 0;
  padding: 0;
}

#search-api-page-search-form-product-search > div .form-item input[type="text"],
#search-api-page-search-form-product-search > div .form-item input[type="search"] {
  border: none;
  padding: 2rem 1rem;
  height: 46px;
}

#search-api-page-search-form-product-search > div .search-button-wrapper input[type="submit"] {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  background-color: #8BC34A;
  color: #fff;
  padding: 1rem 2rem;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#search-api-page-search-form-product-search > div .search-button-wrapper input[type="submit"]:focus {
  outline: none;
}

#search-api-page-search-form-product-search > div .search-button-wrapper input[type="submit"]:hover {
  background-color: #97c95d;
  color: #fff;
}

#search-api-page-search-form-product-search > div .search-button-wrapper input[type="submit"]:disabled, #search-api-page-search-form-product-search > div .search-button-wrapper input[type="submit"].disabled {
  background-color: #DCEDC8;
  cursor: default;
}

#search-api-page-search-form-product-search > div .search-reset {
  flex-basis: 100%;
  margin-top: .5rem;
}

.page-proizvodi .column-first {
  display: none;
}

@media (min-width: 992px) {
  .page-proizvodi .column-first {
    display: block;
  }
}

.page-proizvodi .column-first .panel-pane {
  margin-bottom: 2rem;
}

.page-proizvodi .column-first .pane-title {
  margin-bottom: 1rem;
  font-size: .8rem;
}

.page-proizvodi .column-first .pane-title:after {
  display: none;
}

.page-proizvodi .column-first .facetapi-facetapi-checkbox-links {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #8BC34A;
}

.page-proizvodi .column-first .facetapi-facetapi-checkbox-links li {
  margin: 0;
}

.page-proizvodi .column-first .facetapi-facetapi-checkbox-links li {
  margin-bottom: .1rem;
}

.page-proizvodi .column-first .facetapi-facetapi-checkbox-links a {
  color: #b0bec5;
  text-decoration: none;
  transition: all .1s ease-in-out;
}

.page-proizvodi .column-first .facetapi-facetapi-checkbox-links a:hover {
  color: #8BC34A;
}

.mg-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.5rem;
}

.mg-gallery figure {
  flex: 0 1 50%;
  margin: 0 0 1rem 0;
  padding: 0 .5rem;
}

@media (min-width: 992px) {
  .mg-gallery figure {
    flex: 0 1 25%;
  }
}

.mg-gallery figure img {
  max-width: 100%;
  height: auto;
}

[class*="grid--"],
[class*="grid-sm--"],
[class*="grid-md--"],
[class*="grid-lg--"],
[class*="grid-xl--"] {
  margin-top: -1.667rem;
}

[class*="grid--"] > ul,
[class*="grid-sm--"] > ul,
[class*="grid-md--"] > ul,
[class*="grid-lg--"] > ul,
[class*="grid-xl--"] > ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
  margin-top: 1.667rem;
}

[class*="grid--"] > ul > li,
[class*="grid-sm--"] > ul > li,
[class*="grid-md--"] > ul > li,
[class*="grid-lg--"] > ul > li,
[class*="grid-xl--"] > ul > li {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-top: 1.667rem;
}

[class*="grid--"] > ul > li:nth-child(1),
[class*="grid-sm--"] > ul > li:nth-child(1),
[class*="grid-md--"] > ul > li:nth-child(1),
[class*="grid-lg--"] > ul > li:nth-child(1),
[class*="grid-xl--"] > ul > li:nth-child(1) {
  margin-top: 0;
}

[class*="grid--"].no-gutters > ul,
[class*="grid-sm--"].no-gutters > ul,
[class*="grid-md--"].no-gutters > ul,
[class*="grid-lg--"].no-gutters > ul,
[class*="grid-xl--"].no-gutters > ul {
  margin-right: 0;
  margin-left: 0;
}

[class*="grid--"].no-gutters > ul > li,
[class*="grid-sm--"].no-gutters > ul > li,
[class*="grid-md--"].no-gutters > ul > li,
[class*="grid-lg--"].no-gutters > ul > li,
[class*="grid-xl--"].no-gutters > ul > li {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 768px) {
  [class*="grid--"].equal-height article,
  [class*="grid--"].equal-height .teaser--card,
  [class*="grid-sm--"].equal-height article,
  [class*="grid-sm--"].equal-height .teaser--card,
  [class*="grid-md--"].equal-height article,
  [class*="grid-md--"].equal-height .teaser--card,
  [class*="grid-lg--"].equal-height article,
  [class*="grid-lg--"].equal-height .teaser--card,
  [class*="grid-xl--"].equal-height article,
  [class*="grid-xl--"].equal-height .teaser--card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  [class*="grid--"].equal-height article > div,
  [class*="grid--"].equal-height .teaser--card > div,
  [class*="grid-sm--"].equal-height article > div,
  [class*="grid-sm--"].equal-height .teaser--card > div,
  [class*="grid-md--"].equal-height article > div,
  [class*="grid-md--"].equal-height .teaser--card > div,
  [class*="grid-lg--"].equal-height article > div,
  [class*="grid-lg--"].equal-height .teaser--card > div,
  [class*="grid-xl--"].equal-height article > div,
  [class*="grid-xl--"].equal-height .teaser--card > div {
    width: 100%;
  }
  [class*="grid--"].equal-height article .content-wrapper,
  [class*="grid--"].equal-height .teaser--card .content-wrapper,
  [class*="grid-sm--"].equal-height article .content-wrapper,
  [class*="grid-sm--"].equal-height .teaser--card .content-wrapper,
  [class*="grid-md--"].equal-height article .content-wrapper,
  [class*="grid-md--"].equal-height .teaser--card .content-wrapper,
  [class*="grid-lg--"].equal-height article .content-wrapper,
  [class*="grid-lg--"].equal-height .teaser--card .content-wrapper,
  [class*="grid-xl--"].equal-height article .content-wrapper,
  [class*="grid-xl--"].equal-height .teaser--card .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}

.grid--2-columns > ul > li {
  flex: 0 0 50%;
  max-width: 50%;
}

.grid--2-columns > ul > li:nth-child(2) {
  margin-top: 0;
}

.grid--3-columns > ul > li {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.grid--3-columns > ul > li:nth-child(2), .grid--3-columns > ul > li:nth-child(3) {
  margin-top: 0;
}

.grid--4-columns > ul > li {
  flex: 0 0 25%;
  max-width: 25%;
}

.grid--4-columns > ul > li:nth-child(2), .grid--4-columns > ul > li:nth-child(3), .grid--4-columns > ul > li:nth-child(4) {
  margin-top: 0;
}

@media (min-width: 576px) {
  .grid-sm--2-columns > ul > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-sm--2-columns > ul > li:nth-child(2) {
    margin-top: 0;
  }
  .grid-sm--3-columns > ul > li {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid-sm--3-columns > ul > li:nth-child(2), .grid-sm--3-columns > ul > li:nth-child(3) {
    margin-top: 0;
  }
  .grid-sm--4-columns > ul > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-sm--4-columns > ul > li:nth-child(2), .grid-sm--4-columns > ul > li:nth-child(3), .grid-sm--4-columns > ul > li:nth-child(4) {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .grid-md--2-columns > ul > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-md--2-columns > ul > li:nth-child(2) {
    margin-top: 0;
  }
  .grid-md--3-columns > ul > li {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid-md--3-columns > ul > li:nth-child(2), .grid-md--3-columns > ul > li:nth-child(3) {
    margin-top: 0;
  }
  .grid-md--4-columns > ul > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-md--4-columns > ul > li:nth-child(2), .grid-md--4-columns > ul > li:nth-child(3), .grid-md--4-columns > ul > li:nth-child(4) {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .grid-lg--2-columns > ul > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-lg--2-columns > ul > li:nth-child(2) {
    margin-top: 0;
  }
  .grid-lg--3-columns > ul > li {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid-lg--3-columns > ul > li:nth-child(2), .grid-lg--3-columns > ul > li:nth-child(3) {
    margin-top: 0;
  }
  .grid-lg--4-columns > ul > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-lg--4-columns > ul > li:nth-child(2), .grid-lg--4-columns > ul > li:nth-child(3), .grid-lg--4-columns > ul > li:nth-child(4) {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .grid-xl--2-columns > ul > li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-xl--2-columns > ul > li:nth-child(2) {
    margin-top: 0;
  }
  .grid-xl--3-columns > ul > li {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .grid-xl--3-columns > ul > li:nth-child(2), .grid-xl--3-columns > ul > li:nth-child(3) {
    margin-top: 0;
  }
  .grid-xl--4-columns > ul > li {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-xl--4-columns > ul > li:nth-child(2), .grid-xl--4-columns > ul > li:nth-child(3), .grid-xl--4-columns > ul > li:nth-child(4) {
    margin-top: 0;
  }
}

.list ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.list > ul > li {
  margin-top: 0;
  padding-bottom: .85rem;
  margin-bottom: .85rem;
}

.list > ul > li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.views-infinite-scroll-content-wrapper .list:not(:last-child) {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.column--2-columns > ul, .column--3-columns > ul, .column--4-columns > ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.column--2-columns > ul > li, .column--3-columns > ul > li, .column--4-columns > ul > li {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .column--2-columns > ul, .column--3-columns > ul, .column--4-columns > ul {
    column-count: 1;
    column-gap: 1.25rem;
  }
  .column--2-columns > ul > li, .column--3-columns > ul > li, .column--4-columns > ul > li {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .column--2-columns > ul {
    column-count: 2;
  }
}

@media (min-width: 768px) {
  .column--3-columns > ul {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .column--3-columns > ul {
    column-count: 3;
  }
}

@media (min-width: 768px) {
  .column--4-columns > ul {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .column--4-columns > ul {
    column-count: 3;
  }
}

@media (min-width: 1200px) {
  .column--4-columns > ul {
    column-count: 4;
  }
}

.masonry--2-columns .masonry-layout,
.masonry--3-columns .masonry-layout,
.masonry--4-columns .masonry-layout {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.masonry--2-columns .masonry-layout .masonry-item,
.masonry--3-columns .masonry-layout .masonry-item,
.masonry--4-columns .masonry-layout .masonry-item {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 1.6rem;
}

@media (min-width: 768px) {
  .masonry--2-columns .masonry-layout .masonry-item,
  .masonry--2-columns .masonry-layout .masonry-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .masonry--3-columns .masonry-layout .masonry-item,
  .masonry--3-columns .masonry-layout .masonry-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .masonry--3-columns .masonry-layout .masonry-item,
  .masonry--3-columns .masonry-layout .masonry-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media (min-width: 768px) {
  .masonry--4-columns .masonry-layout .masonry-item,
  .masonry--4-columns .masonry-layout .masonry-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .masonry--4-columns .masonry-layout .masonry-item,
  .masonry--4-columns .masonry-layout .masonry-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media (min-width: 1200px) {
  .masonry--4-columns .masonry-layout .masonry-item,
  .masonry--4-columns .masonry-layout .masonry-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.view--2-columns,
.view--3-columns {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.view--2-columns > div,
.view--3-columns > div {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  flex: 0 0 100%;
  max-width: 100%;
}

.view--2-columns .attachment .views-element-container:not(:last-child),
.view--3-columns .attachment .views-element-container:not(:last-child) {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.view--2-columns .contextual,
.view--3-columns .contextual {
  display: none;
}

@media (max-width: 767.98px) {
  .view--2-columns .attachment-before {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .view--2-columns .attachment-after {
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

@media (min-width: 768px) {
  .view--2-columns > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .view--2-columns > div .teaser--small-item .teaser--media {
    width: 130px;
  }
}

@media (max-width: 991.98px) {
  .view--3-columns .attachment-before {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .view--3-columns .attachment-after {
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .view--3-columns > div .teaser--small-item .teaser--media {
    width: 130px;
  }
}

@media (min-width: 992px) {
  .view--3-columns > div {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

.no-borders .teaser {
  border: none !important;
}

.items--separator--border > ul > li {
  border-bottom: 1px solid #eceff1;
}

.items--spacer--tiny > ul > li,
.views-infinite-scroll-content-wrapper .list.items--spacer--large > ul > li:not(:last-child) {
  margin-bottom: .5rem !important;
  padding-bottom: .5rem !important;
}

.items--spacer--large > ul > li,
.views-infinite-scroll-content-wrapper .list.items--spacer--large > ul > li:not(:last-child) {
  margin-bottom: 2rem !important;
  padding-bottom: 2rem !important;
}

.view-frontpage-news.view-display-id-block {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.view-frontpage-news.view-display-id-block > div {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

@media (min-width: 992px) {
  .view-frontpage-news.view-display-id-block > .view-content {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .view-frontpage-news.view-display-id-block > .attachment-after {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.user-login-form-wrapper {
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem  2rem;
}

@media (min-width: 768px) {
  .user-login-form-wrapper {
    padding: 2rem;
  }
}

.user-login-form-wrapper nav.tabs {
  margin-bottom: 2rem !important;
}

.user-login-form-wrapper input[type="submit"] {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  background-color: #8BC34A;
  color: #fff;
  padding: 1rem 2rem;
  display: block;
  width: 100%;
}

.user-login-form-wrapper input[type="submit"]:focus {
  outline: none;
}

.user-login-form-wrapper input[type="submit"]:hover {
  background-color: #97c95d;
  color: #fff;
}

.user-login-form-wrapper input[type="submit"]:disabled, .user-login-form-wrapper input[type="submit"].disabled {
  background-color: #DCEDC8;
  cursor: default;
}

.user-login-form-wrapper form > div > .form-wrapper {
  padding: 0;
}

.user-login-form-wrapper #edit-account {
  border: none;
  padding: 0;
}

.hybridauth-widget-wrapper ul.hybridauth-widget {
  display: flex;
}

.hybridauth-widget-wrapper ul.hybridauth-widget li {
  flex: 1;
}

.btn-hybrid {
  padding-top: 12px;
  padding-bottom: 12px;
}

a.hybridauth-widget-provider {
  color: #FFF;
  text-align: center;
  text-decoration: none;
}

a.hybridauth-widget-provider .facebook-f {
  background: #3b5998;
}

a.hybridauth-widget-provider .google {
  background: #d34836;
}

a.hybridauth-widget-provider .twitter {
  background: #00aced;
}

@media (max-width: 767.98px) {
  a.hybridauth-widget-provider .twitter span {
    display: none;
  }
}

.or-separator {
  position: relative;
  margin: 1.5em 0;
  text-align: center;
}

.or-separator:after {
  content: "";
  width: 100%;
  border-bottom: 1px solid #b0bec5;
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
}

.or-separator span {
  background: #FFF;
  padding: 0 5px;
  font-weight: bold;
  position: relative;
  z-index: 5;
}

.user-profile .page-title {
  color: #FFF;
}

.user-profile nav.tabs {
  margin-bottom: 0 !important;
}

.address-book-wrapper .view-commerce-addressbook-defaults {
  background-color: #f0f7e7;
  border: 1px solid #C5E1A5;
  padding: 1rem;
}

.text-primary {
  color: #8BC34A !important;
}

.text-primary-light {
  color: #C5E1A5 !important;
}

.text-primary-lightest {
  color: #DCEDC8 !important;
}

.text-primary-dark {
  color: #407A28 !important;
}

.text-primary-accent {
  color: #B2FF59 !important;
}

.text-dark {
  color: #475962 !important;
}

.text-dark-secondary {
  color: rgba(71, 89, 98, 0.5) !important;
}

.text-dark-hint {
  color: rgba(71, 89, 98, 0.3) !important;
}

.text-light {
  color: #fff !important;
}

.text-light-secondary {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-light-hint {
  color: rgba(255, 255, 255, 0.3) !important;
}

.bg-primary {
  background-color: #8BC34A !important;
}

.bg-primary-light {
  background-color: #C5E1A5 !important;
}

.bg-primary-lightest {
  background-color: #DCEDC8 !important;
}

.bg-primary-dark {
  background-color: #407A28 !important;
}

.bg-primary-accent {
  background-color: #B2FF59 !important;
}

.bg-secondary {
  background-color: #475962 !important;
}

.bg-secondary-light {
  background-color: #b0bec5 !important;
}

.bg-secondary-lightest {
  background-color: #eceff1 !important;
}

.bg-secondary-dark {
  background-color: #263238 !important;
}

.img-w-100,
.img-w-100 img {
  width: 100%;
  height: auto;
}

.icon:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  margin-right: .2rem;
}

.icon--plus:before {
  content: '\f067';
}

.icon--sign-in-alt:before {
  content: '\f2f6';
}

.icon--user:before {
  content: '\f007';
}

.icon--sign-out-alt:before {
  content: '\f2f5';
}

.icon-brand:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Brands";
  margin-right: .2rem;
}

.icon-brand--facebook-f:before {
  content: '\f39e';
}

.icon-brand--twitter:before {
  content: '\f099';
}

.icon-brand--instagram:before {
  content: '\f16d';
}

.icon-brand--youtube:before {
  content: '\f167';
}
