.product {

  .field-name-field-product-image {
    margin-top: -.6rem;
    //margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // Категорија - тип на производ
  //
  .field-name-field-product-type {
    color: $color--gray-light;
    margin-bottom: .3rem;
    a {
      @include link-style--secondary;
    }
  }

  .product__header {
    border-bottom: 1px solid $color--gray-lightest;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
  }

  // Караток опин са производот
  //
  .field-name-field-product-short-description {
    @include media-breakpoint-up(lg) {
      font-size: .875rem;
    }
  }

  // Ценана производот
  //
  .rrp {
    display: flex;
    align-items: flex-end;

    &__original {
      color: $color--gray-light;
      text-decoration: line-through;
      margin-right: .25rem;
      .price__amount {
        font-weight: normal;
        margin-right: .1rem;
      }
    }

    &__sale {
      .price__amount {
        margin-right: .2rem;
        color: $color--gray-dark;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
      }
    }
  }

  // Форма - Додади во кошница
  //
  .commerce-add-to-cart {
    background-color: lighten($color--gray-lightest, 3%);
    margin-top: 2rem;
    padding: 1.5rem;
    //border-top: 1px solid $color--gray-lightest;
    //border-bottom: 1px solid $color--gray-lightest;

    input, select {
      background-color: #FFF;
    }

    .form-item {
      margin: 0;
    }
    .form-wrapper {
      margin: 0;
      padding: 0;
      &:empty {
        display: none;
      }
    }

    // Врапер на сите елементи во формата.
    > div {
      display: flex;
      flex-wrap: wrap;
    }

    // Вариација
    .form-type-select {
      flex: 0 0 100%;
      margin-bottom: 1rem;
      select {
        width: 100%;
      }
    }

    .form-item-quantity {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      label {
        display: none;
      }
      input {
        width: 3rem;
        margin: 0 .25rem;
        padding: .8rem 0;
        //border-top: 1px solid $color--gray-lightest;
        //border-bottom: 1px solid $color--gray-lightest;
        border: none;
        font-size: 1rem;
        text-align: center;
      }
    }

    input[type='submit'] {
      @include button;
      @include button-sizing('large');
      @include button-primary;
      flex: 1;
    }
  }
}