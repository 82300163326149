// ---------------
// Helpers
// ---------------

// Reset ul/ol lists
@mixin  list__reset {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
  }
}

// CSS image replacement
@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin full-width() {
  margin-left: -9999rem;
  margin-right: -9999rem;
  padding-left: 9999rem;
  padding-right: 9999rem;
  position: static;
}

@mixin make-blank-overlay() {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin make-color-overlay($bg-color) {
  @include make-blank-overlay();
  background: $bg-color;
}

// ---------------

// Menu
//
@mixin drupal-vertical-menu() {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  a {
    display: block;
    transition: all .15s ease-in-out;
    text-decoration: none;
  }
}

// Make the element Font Awesome ready.
//
@mixin make-fa-icon-ready() {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

@mixin fa-icons() {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
}

@mixin fa-brands() {
  font-family: "Font Awesome 5 Brands";
}



// Links
//

@mixin link-style--secondary() {
  color: $color--gray-light;
  text-decoration: none;
  transition: color .2s ease-in-out;
  &:hover {
    color: $color--green;
  }
}

// Buttons
//
@mixin button() {
  display: block;
  border: none;
  border-radius: .2rem;
  line-height: 1;
  text-decoration: none!important;
  transition: all .15s ease-in-out;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}


@mixin button-sizing($size: 'default') {
  @if $size == 'small' {
    padding: .5rem .7rem;
    font-size: .875rem;
  }
  @if $size == 'default' {
    padding: .7rem 1.5rem;
  }
  @else if $size == 'large' {
    padding: 1rem 2rem;
  }
  @else if $size == 'x-large' {
    padding: 1.2rem 3rem;
    font-size: 1.2rem;
  }
}

@mixin button-primary() {
  background-color: $color--green;
  color: $white;
  &:hover {
    background-color: lighten($color--green, 5%);
    color: $white;
  }
  &:disabled, &.disabled {
    background-color: $color--green-lightest;
    cursor: default;
  }
}