.term-header {
  background-color: #FFF;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 1.5rem 0;
  border-bottom: 1px solid $color--gray-lightest;
  position: relative;
  z-index: 2;
  @include media-breakpoint-up(lg) {
    background-size: auto;
  }
  @include media-breakpoint-up(lg) {
    padding: 2.5rem 0;
  }

  // Brand logo
  .field-name-field-brand-logo {
    width: 100px;
    img { @include img-fluid; }
    @include media-breakpoint-up(md) {
      width: 150px;
    }
    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  // Brand Ddescription
  .taxonomy-term-description {
    display: none;
    color: $color--gray-light;
    font-size: $font-size-sm;
    @include media-breakpoint-up(lg) {
      display: block;
      margin: .2rem 0 0;
    }
    @include media-breakpoint-up(lg) {
      margin: .5rem 0 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}