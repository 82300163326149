html {
  font-size: .875rem;
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

html, body {
  overflow-x: hidden;
}

a:link, a:hover, a:focus, a:active {
  outline: none;
}

