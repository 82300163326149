$tabs-sd-bg-color: $color--gray-lightest !default;
$tabs-sd-active-width: 80% !default;
$tabs-sd-height: 40px !default;


//
// Tabs
//

nav.tabs {
  margin: 0 0 2rem;
}

.tabs {
  padding-top: $tabs-sd-height;
  border: 1px solid $color--gray-lightest;
  border-radius: .25rem;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
    border: none;
    border-radius: 0;
  }
}


//
// Primary & Secondary
//
ul.primary,
ul.secondary {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn-tabs-toggler {
  background-color: $tabs-sd-bg-color;
  display: block;
  width: 100% - $tabs-sd-active-width;
  height: $tabs-sd-height;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}


//
// Primary tabs
//
.tabs ul.primary {
  background-color: $tabs-sd-bg-color;
  display: block;
  margin-top: -1px;
  border: 0;
  border-radius: .25rem;
  position: static;
  max-height: 0;
  overflow: hidden;
  &.active {
    max-height: 999rem;
  }
  @include media-breakpoint-up(lg) {
    background-color: transparent;
    display: flex;
    max-height: none;
    align-items: baseline;
    padding: 0;
    border: none;
    border-bottom: 1px solid $gray-blue-100;
    overflow: visible;
  }
  > li {
    display: block;
    margin: 0;
    &.active {
      width: $tabs-sd-active-width;
      position: absolute;
      left: 0;
      top: 0;
      @include media-breakpoint-up(lg) {
        width: auto;
        position: static;
      }
    }
    a {
      display: block;
      background: transparent;
      height: $tabs-sd-height;
      padding: 0 1.5rem;
      border-top: 1px solid #FFF;
      color: $gray-blue-600;
      line-height: $tabs-sd-height;
      text-decoration: none;
      text-align: left;
      transition: all .2s ease-in-out;
      @include media-breakpoint-up(lg) {
        height: auto;
        line-height: 1rem;
        margin-bottom: -2px;
        padding: .5rem 1rem;
        border: none;
        border-bottom: 3px solid transparent;
        text-align: center;
      }
      &:hover {
        color: $gray-blue-900;
        border-bottom-color: $color--green;
      }
    }
    &.active a {
      color: #000;
      font-weight: bold;
      border-top: none;
      @include media-breakpoint-up(lg) {
        border-bottom-color: $color--green;
      }
    }
  }
}

ul.tabs.secondary {
  display: flex;
  > li a {
    display: block;
    padding: .5rem 1rem;
    color: $gray-blue-300;
  }
}


// Tabs variations
//
body.node-type-article,
body.node-type-blog-post {
  nav.tabs {
    margin-bottom: 0;
    ul.primary {
      justify-content: center;
      > li > a {
        @include media-breakpoint-up(lg) {
          padding: 1rem;
        }
      }
    }
  }
}

// Tabs on user page
//

main.user-profile {
  nav.tabs {
    margin: 2rem 0 0 0;
    @include media-breakpoint-up(lg) {
      margin: 0 0 1.5rem 0;
    }
    ul.primary {
      @include media-breakpoint-up(lg) {
        @include full-width;
        justify-content: space-between;
      }
      > li {
        flex: 1;
      }
      > li > a {
        @include media-breakpoint-up(lg) {
          padding: 1rem;
        }
      }
    }
  }
}
