.natusana-section {
  &__title {
    margin-bottom: 1.5rem;
    letter-spacing: -.01rem;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    &:after {
      @include zig-zag--small;
      margin: .5rem auto 0;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
      font-size: 2.5rem;
      &:after {
        @include zig-zag;
        margin: 1.5rem auto 0;
      }
    }
  }

  // Small vertical padding
  //
  &--v-padding-small {
    padding: 1.667rem 0;
  }

  // Mediumvertical padding
  //
  &--v-padding-medium {
    padding: 1.667rem 0;
    @include media-breakpoint-up(md) {
      padding: 4rem 0;
    }
  }

  // Large vertical padding
  //
  &--v-padding-large {
    padding: 2.5rem 0;
    @include media-breakpoint-up(md) {
      padding: 6rem 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 6rem 0;
    }
  }
}
