// Блок со вести на насловна страна
//

.view-frontpage-news.view-display-id-block {
  @include make-row;
  > div {
    @include make-col-ready;
  }
  > .view-content {
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
    .teaser__headline {
      @include media-breakpoint-up(md) {
        //font-size: 2rem;
      }
    }
  }
  > .attachment-after {
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }
}
