@mixin zig-zag() {
  content: "";
  display: block;
  width: 93px;
  height: 12px;
  background-image: url("../images/zig-zag.svg");
  background-repeat: no-repeat;
}

@mixin zig-zag--small() {
  content: "";
  display: block;
  width: 63px;
  height: 9px;
  background-image: url("../images/zig-zag-small.svg");
  background-repeat: no-repeat;
}

// Zig-Zag large
//
.zig-zag::after {
  @include zig-zag
}


// Zig-Zag small
//
.zig-zag--small::after {
  @include zig-zag--small
}