//
// Color system
//

@import "colors";

//
// Bootstrap colors
//

$gray-50: #eceff1;
$gray-100: #cfd8dc;
$gray-200: #b0bec5;
$gray-300: #90a4ae;
$gray-400: #78909c;
$gray-500: #607d8b;
$gray-600: #546e7a;
$gray-700: #455a64;
$gray-800: #475962;
$gray-900: #263238;

$color--green-dark: #407A28;
$color--green: #8BC34A;
$color--green-light: #C5E1A5;
$color--green-lightest: #DCEDC8;
$color--green-accent: #B2FF59;

$color--gray-dark: $gray-900;
$color--gray: $gray-800;
$color--gray-light: $gray-200;
$color--gray-lightest: $gray-50;



// ================
// Bootstrap
// ================

$primary:       $color--green;
$secondary:     $color--gray;
$success:       $color--green;
$info:          $cyan;
//$warning:       $yellow;
//$danger:        $red;
$light:         $color--gray-lightest;
$dark:          $color--gray-dark;


// -----------
// Grid
// -----------

$grid-gutter-width: 1.875rem;


// -----------
// Spacing
// -----------

$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 2.5),
    7: ($spacer * 3),
    8: ($spacer * 4),
    9: ($spacer * 5),
    10: ($spacer * 6),
    11: ($spacer * 7),
    12: ($spacer * 8),
    13: ($spacer * 9),
    14: ($spacer * 10),
  ), $spacers
);

// -----------
// Body
// -----------

$body-bg: #F7F7F7;
$body-color: $color--gray;

// -----------
// Type
// -----------
$font-family-base: 'Roboto', sans-serif;

$headings-margin-bottom:($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: $color--gray-dark;

$display1-weight: 900;
$display2-weight: 900;
$display3-weight: 900;
$display4-weight: 900;
$display-line-height: $headings-line-height;


// -----------
// Tables
// -----------

$table-border-color:          lighten($color--gray-light, 10%);

$table-head-bg:               lighten($color--gray-light, 10%);
$table-head-color:            lighten($color--gray-light, 10%);
