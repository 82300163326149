//
// Slick - Brands carousel option set.
//

.slick--optionset--promo-slider {
  position: relative;

  .image-slide {
    height: 30vh;
    position: relative;
    @include media-breakpoint-up(lg) {
      height: 60vh;
    }
  }
  
  .promo-link {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .slick__arrow {
    margin: 0;

    button {
      background-color: transparent;
      border: none;
      color: #FFF;
      font-size: 2rem;
      transition: all .2s ease-in-out;
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.4s;
        z-index: -1;
      }
      &:hover {
        color: #FFF;
        &:before {
          opacity: 1;
        }
      }
      &:focus {
        outline: 0;
      }
    }

    .slick-prev {
      //background-image: linear-gradient(to left, rgba($color--gray-dark, 0), rgba($color--gray-dark, .3));
      width: 50px;
      &:before {
        //background-image: linear-gradient(to left, rgba($color--gray-dark, 0), rgba($color--gray-dark, .2));
      }
      @include media-breakpoint-up(md) {
        width: 80px;
      }
    }

    .slick-next {
      //background-image: linear-gradient(to right, rgba($color--gray-dark, 0), rgba($color--gray-dark, .3));
      width: 50px;
      right: 0;
      &:before {
        //background-image: linear-gradient(to right, rgba($color--gray-dark, 0), rgba($color--gray-dark, .2));
      }
      @include media-breakpoint-up(md) {
        width: 79px;
      }
    }
  }
}


//
// Slick - Brands carousel option set.
//

.slick--optionset--brandscarousel {
  position: relative;

  .slick__arrow {
    margin: 0;

    button {
      background-color: transparent;
      border: none;
      color: #FFF;
      font-size: 2rem;
      transition: all .2s ease-in-out;
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.4s;
        z-index: -1;
      }
      &:hover {
        color: #FFF;
        &:before {
          opacity: 1;
        }
      }
      &:focus {
        outline: 0;
      }
    }

    .slick-prev {
      background-image: linear-gradient(to left, rgba($color--gray-dark, 0), rgba($color--gray-dark, .3));
      width: 50px;
      &:before {
        background-image: linear-gradient(to left, rgba($color--gray-dark, 0), rgba($color--gray-dark, .2));
      }
      @include media-breakpoint-up(md) {
        width: 80px;
      }
    }

    .slick-next {
      background-image: linear-gradient(to right, rgba($color--gray-dark, 0), rgba($color--gray-dark, .3));
      width: 50px;
      right: 0;
      &:before {
        background-image: linear-gradient(to right, rgba($color--gray-dark, 0), rgba($color--gray-dark, .2));
      }
      @include media-breakpoint-up(md) {
        width: 79px;
      }
    }
  }
}


//
// Slick - Two column carousel option set.
//

.slick--optionset--two-column-carousel {
  position: relative;

  .slick__arrow {
    button {
      background: transparent;
      display: flex;
      align-items: center;
      height: 50px;
      border: none;
      color: $color--green-light;
      font-size: 2rem;
      line-height: 1;
      position: absolute;
      top: calc(50% - 25px);
      cursor: pointer;
      transition: all .2s ease-in-out;
      &:hover {
        color: $color--green;
      }
      &:focus {
        outline: 0;
      }
    }

    .slick-prev {
      left: -10%;
    }

    .slick-next {
      right: -10%;
    }
  }

  // Dots
  //
  .slick-dots {
    @include list__reset;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;

    li {
      margin: 0 .3rem;

      button {
        @include text-hide(true);
        background: $color--green-light;
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      &.slick-active button {
        background: $color--green;
      }
    }
  }
}
