.block-commerce-checkout-progress {
}

ol.commerce-checkout-progress {
  @include list__reset;
  display: flex;
  // justify-content: space-between;
  padding: 0;
  margin: 0 0 1.5rem 0;
  font-size: .8rem;
  li {
    width: auto!important;
    float: none!important;
    color: $color--gray-light;
    font-weight: normal;
    white-space: nowrap;
    &:after {
      content: "/";
      display: inline-block;
      margin: 0 .5rem;
    }
    &.active {
      color: $color--green;
      font-weight: bold;
    }
  }
}
