.header {
  position: relative;
  z-index: 10;
  .logo {
    display: inline-block;
    margin: .4rem;
  }
}

// Header Top
//
.header__top {
  font-size: $font-size-sm;
  .block-menu ul.menu {
    @include drupal-vertical-menu;
    display: flex;
    a {
      padding: .6rem;
      color: #FFF;
      &:hover {
        background: rgba($color--gray, .2);
      }
    }
  }
}

// User menu
//

body.logged-in .header__top [href="/user"] {
  background-color: $color--green;
}


// Cart button
//
.ajax-cart__button {
  background-color: transparent;
  padding: .5rem 1rem;
  color: $color--gray;
  position: relative;
  &:hover {
    color: $color--green;
  }
  @include media-breakpoint-up(lg) {
    border-left: 1px solid $color--gray-light;
  }
  i {
    font-size: 2rem;
    line-height: 1;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }
  .badge {
    position: absolute;
    top: 6px;
    right: 5px;
    color: #FFF;
  }
}

// Hamburger menu button
//
.button--hamburger-menu {
  @extend .ajax-cart__button;
  border-left: 1px solid $color--gray-light;
}