// Генерално све наследува од article тизерот,
// само има некои ситни модификации.

.teaser--card.teaser--blog {
  background-color: #FFF;
  transition: all .2s ease-in-out;
  .teaser__image a::before {
    display: none;
  }

  .teaser__channel {
    margin-bottom: .5rem;
  }

  .teaser__content {
    padding: 1.5rem 1.5rem;
  }

  &:hover {
    box-shadow: 0 0 3rem 0 rgba(#000, .1);
  }
}
