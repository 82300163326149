#search-api-page-search-form-product-search > div {
  display: flex;
  flex-wrap: wrap;
  .form-item {
    background-color: #FFF;
    border: 1px solid $color--green-light;
    flex: 1;
    margin: 0;
    padding: 0;
    input[type="text"],
    input[type="search"] {
      border: none;
      padding: 2rem 1rem;
      height: 46px;
    }
  }
  .search-button-wrapper {
    input[type="submit"] {
      @include button;
      @include button-primary;
      @include button-sizing('large');
      height: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .search-reset {
    flex-basis: 100%;
    margin-top: .5rem;
  }
}


// Прва колона - Фацет филтер
//
.page-proizvodi .column-first {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }

  .panel-pane {
    margin-bottom: 2rem;
  }

  .pane-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    &:after {
      display: none;
    }
  }

  .facetapi-facetapi-checkbox-links {
    @include list__reset;
    color: $color--green;
    li {
      margin-bottom: .1rem;
    }
    a {
      color: $color--gray-light;
      text-decoration: none;
      transition: all .1s ease-in-out;
      &:hover  {
        color: $color--green;
      }
    }
  }
}
