.panel-pane {
}

.pane-title {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
  color: $color--gray-dark;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  &:after {
    @include zig-zag--small;
    margin-left: .6rem;
  }
}

.panel-pane--small {
}