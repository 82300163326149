// Product teaser card
//
.product-teaser {
  background-color: #FFF;
  transition: all .2s ease-in-out;
  &:hover {
    box-shadow: 0 0 3rem 0 rgba(#000, .1);
  }

  &__image {
    @include img-fluid;
    text-align: center;
  }

  &__content {
    text-align: center;
    padding: 2rem 1rem;
    &:before {
      @include zig-zag--small;
      margin: -1rem auto 1rem;
    }
  }

  &__category {
    margin-bottom: .2rem;
    font-size: .875rem;
    color: $color--gray-light;
  }

  &__title {
    color: $color--gray-dark;
    font-size: 1.125rem;
    font-weight: 500;
  }

  .rrp {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    line-height: 1;

    &__original {
      margin-right: .3rem;
      color: $color--gray-light;
      font-size: .875rem;
      text-decoration: line-through;
    }
    &__sale {
      font-size: 1.125rem;
    }
  }

  .commerce-add-to-cart {
    padding: 0 0 2rem;
    .form-wrapper {
      margin: 0;
      padding: 0;
      &:empty {
        display: none;
      }
    }
  }

  // Hide variation select
  .form-type-select {
    display: none;
    position: fixed;
    margin-top: -99999rem;
  }

  input[type='submit'] {
    @include button;
    @include button-sizing('small');
    @include button-primary;
    margin: auto;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }

  a {
    text-decoration: none;
  }
}

// Products cards grid
//
.products-grid {
  @include media-breakpoint-down(sm) {
    [class*="grid--"] > ul > li {
      margin-top: 1rem;
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}
