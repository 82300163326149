.add-cart-message {
  background: #FFF;
  padding: 2rem;

  &__content {
    margin-bottom: 1.5rem;
    &:before {
      content: "\f291";
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 80px;
      width: 80px;
      height: 80px;
      margin: 0 auto 1.2rem;
      background-color: $color--green-light;
      border: 1px solid $color--green-light;
      border-radius: 50%;
      color: $color--green;
      font-size: 2.5rem;
      font-style: normal;
      font-variant: normal;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  &__description {
    margin-bottom: .2;
    color: $color--gray-light;
    font-size: 80%;
  }

  &__product-title {
    color: $color--gray-dark;
    font-size: 1.5rem;
  }

  &__checkout-button {
    @include button;
    @include button-primary;
    @include button-sizing;
  }

  &__continue-shopping {
    margin-top: .5rem;
    color: $color--gray-light;
    font-size: 80%;
  }

  .price {
    &__amount {
      margin-right: .1rem;
    }
  }

}
