// -----------------------------
// Text color variations
// -----------------------------

.text {
  // Brand color text
  &-primary { color: $color--green !important; }
  &-primary-light { color: $color--green-light !important; }
  &-primary-lightest { color: $color--green-lightest !important; }
  &-primary-dark { color: $color--green-dark !important; }
  &-primary-accent { color: $color--green-accent !important; }

  // Dark text color
  &-dark { color: $body-color !important; }
  &-dark-secondary { color: rgba($body-color, .5) !important; }
  &-dark-hint { color: rgba($body-color, .3) !important; }

  // Light text color
  &-light { color: $white !important; }
  &-light-secondary { color: rgba($white, .5) !important; }
  &-light-hint { color: rgba($white, .3) !important; }
}


// -----------------------------
// Background color variations
// -----------------------------

.bg {
  &-primary { background-color: $color--green !important; }
  &-primary-light { background-color: $color--green-light !important; }
  &-primary-lightest { background-color: $color--green-lightest !important; }
  &-primary-dark { background-color: $color--green-dark !important; }
  &-primary-accent { background-color: $color--green-accent !important; }

  &-secondary { background-color: $color--gray !important; }
  &-secondary-light { background-color: $color--gray-light !important; }
  &-secondary-lightest { background-color: $color--gray-lightest !important; }
  &-secondary-dark { background-color: $color--gray-dark !important; }
}


// -----------------------------
// 100 image width
// -----------------------------

.img-w-100, 
.img-w-100 img {
  width: 100%;
  height: auto;
}


// -----------------------------
// FA Pseudo icons
// -----------------------------

.icon {
  &:before {
    @include make-fa-icon-ready;
    @include fa-icons;
    margin-right: .2rem;
  }

  &--plus:before { content: '\f067'; }
  &--sign-in-alt:before { content: '\f2f6'; }
  &--user:before { content: '\f007'; }
  &--sign-out-alt:before { content: '\f2f5'; }
}

.icon-brand {
  &:before {
    @include make-fa-icon-ready;
    @include fa-brands;
    margin-right: .2rem;
  }

  &--facebook-f:before { content: '\f39e'; }
  &--twitter:before { content: '\f099'; }
  &--instagram:before { content: '\f16d'; }
  &--youtube:before { content: '\f167'; }
}


