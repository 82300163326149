.pager {
  @include list__reset;
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  a, .pager-current {
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    transition: all .1s ease-in-out;
    &:hover {
      background: rgba(#FFF, .7);
    }
  }

  .pager-current {
    background: #FFF;
    font-weight: bold;
  }
}
