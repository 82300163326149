.user-profile {
  
  .page-title {
    color: #FFF;
  }

  nav.tabs {
    margin-bottom: 0!important;
  }
}
