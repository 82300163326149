.brand-teaser {
  @include media-breakpoint-up(md) {
    //margin: 0 10px;
  }
  @include media-breakpoint-up(xl) {
    //margin: 0 15px;
  }

  &__media {
    img {
      @include img-fluid
    }
  }
  &__content {
    padding: 2rem;
  }

  &__logo {
    margin-bottom: 1rem;
  }

  &__description {
    color: $color--gray-light;
    @include media-breakpoint-up(md){
      font-size: .875rem;
    }
  }

  a {
    display: block;
    text-decoration: none;
    transition: all .2s ease-in-out;
    &:hover {
      box-shadow: 0 0 3rem 0 rgba(#000, .1);
      background-color: #FFF;
    }
  }
}