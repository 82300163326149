.page-cart {
  .form-actions {
    padding-right: 0;
  }
}


.view-commerce-cart-form {
  overflow: auto;

  table {
    td {
      vertical-align: middle;
    }
  }

  // Quantity field
  //
  .views-field-edit-quantity {
    > div {
      display: flex;
      align-items: center;
    }

    input {
      width: auto;
      padding: .2rem;
      text-align: center;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px
    }
  }

  // Remove button
  //
  input.delete-line-item {
    background: transparent;
    border: none;
    color: $red-300;
    cursor: pointer;
    &:hover {
      color: $red-500;
    }
    &:focus {
      outline: none;
    }
  }

  // Total
  //
  .line-item-summary {
    display: flex;
    justify-content: flex-end;;

    .line-item-total {
      width: 200px;
      border-top: 2px solid $color--gray;
      color: $color--gray-dark;
      font-size: 1.2rem;
      // vertical-align: bottom;
      // line-height: 1;
    }

    .price__amount {
      font-size: 1.5rem;
    }
  }
}
