// ----------------------------
// Fieldset
// ----------------------------

fieldset.form-wrapper {
  background-color: #FFF;
  margin-bottom: 2rem;
  legend {
    display: block;
    background-color: $gray-100;
    color: $gray-blue-600;
    padding: 1rem;
    margin-bottom: 0;
    font-size: .9rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
  }
  .fieldset-wrapper {
    padding: 1.5rem;
    //border: 1px solid $color--gray-lightest;
    border-top: none;
    .form-item:last-child {
      margin-bottom: 0;
    }
  }
}

fieldset.collapsible {
  legend {
    padding: 0;
  }
  legend a {
    background-color: $gray-100;
    display: flex;
    padding: 1rem;
    color: $gray-blue-600;
    text-decoration: none;
    transition: background-color .2s;
    &:before {
      display: block;
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      margin-right: .5rem;
    }
  }

  &.collapsed a {
    background-color: $gray-100;
    &:hover {
      background-color: $color--gray-lightest;
    }
    &:before {
      content: "\f107";
    }
  }
}


// ----------------------------
// Form Wrapper
// ----------------------------
form > div > .form-wrapper:not(fieldset) {
  background-color: #FFF;
  padding: 2rem;
  margin-bottom: 2rem;
}

// ----------------------------
// Form Item
// ----------------------------

.form-item {
  margin-bottom: 1.5rem;
  label[for^="edit-"]:not(.option) {
    margin-bottom: .25rem;
    padding: 0;
    color: $color--green;
    font-size: .7rem;
    text-transform: uppercase;
  }
  .description {
    color: $gray-blue-300;
    font-size: .8rem;
  }

  .form-type-checkbox,
  .form-type-radio {
    margin-bottom: 0;
  }
}

// Form item in table
table .form-item {
  &:last-child {
    margin-bottom: 0;
  }
}


// ----------------------------
//  Text input fields
// ----------------------------

%text-input {
  background-color: transparent;
  display: block;
  width: 100%;
  padding: .8rem;
  border: none;
  border: 1px solid lighten($color--gray-light, 10%);
  outline: none;
  box-sizing: border-box;
  transition: border-color .15s;
  -webkit-appearance: none;
  &:focus, &:active {
    border-color: $color--green;
    outline: none;
    &::placeholder {
      opacity: .7;
    }
  }
  &::placeholder {
    color: $color--gray-light;
    transition: opacity .2s;
  }
}

@each $type in text, password, color, date, datetime-local, email, month, number, range, search, tel, time, url, week {
  input[type=#{$type}] {
    @extend %text-input;
  }
}


// ----------------------------
// Text Area
// ----------------------------
textarea {
  @extend %text-input;
  padding: .5rem;
  border: 1px solid $color--gray-light;
  font-size: 1rem;
  -webkit-appearance: none;
}


// ----------------------------
// Select
// ----------------------------
select {
  background-color: transparent;
  background-image: url("../images/caret-down.svg");
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
  display: block;
  padding: 0 calc(1rem + 30px) 0 1rem;
  border: 1px solid $color--gray-lightest;
  font-size: 1rem;
  outline: none;
  box-sizing: border-box;
  height: 2.5rem;
  color: $color--gray;
  -webkit-appearance: none;
}


// ----------------------------
// Password confirm form
// ----------------------------
.form-type-password-confirm {
  .form-item {
    margin-bottom: 0;
  }
}

// --------------------
// Buttons
// --------------------

input[type='submit'] {
  @include button;
  @include button-primary;
  @include button-sizing();
  display: inline-block;
}

.form-actions {
  &.form-wrapper {
    margin-bottom: 0;
  }
  input[type='submit']:not(:last-child) {
    margin-right: .5rem;
  }
}
