// Footer
//

.footer {
  display: block;
  font-size: $font-size-sm;
}

.footer__main {
  padding: 1rem;
}

.footer__bottom {
  padding: 1rem;

  .block-menu ul.menu {
    @include drupal-vertical-menu;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
    a {
      padding: .7rem .3rem;
      border-bottom: 1px solid rgba($color--gray-light, .5);
      color: $color--gray-light;
      @include media-breakpoint-up(lg) {
        padding: 0 .3rem;
        border: none;
      }
      &:hover, &.active-trail {
        color: $color--gray;
        text-decoration: underline;
      }
      &.active-trail {
        text-decoration: none;
      }
    }
  }
}

// Card acceptances block
//
.card-acceptances {
  background-image: url("../images/card-acceptances.svg");
  background-position: center center;
  background-size: cover;
  background-color: #F7F7F7;
  background-blend-mode: luminosity;
  width: 279px;
  height: 32px;
  margin: auto;
  @include media-breakpoint-up(md) {
    //display: block;
  }
}