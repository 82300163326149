.button {
  @include button;

  // Sizing
  //
  &--small    { @include button-sizing('smal') }
  &--default  { @include button-sizing('default') }
  &--large    { @include button-sizing('large') }
  &--x-large  { @include button-sizing('x-large') }

  &--primary  {  @include button-primary; }
}