.region-modal-cart {
  margin: 0 1rem;
}


// --------------
// AJAX CART
// --------------

// Ajax cart
//
.ajax-cart {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 600px;
  }
}


// Empty cart
.ajax-cart__empty {
  padding: 2rem;
  text-align: center;
}

.ajax-cart__empty__message {
  margin: 1.5rem 0;
  color: $color--gray-light;
  font-size: 1.1rem;
  font-weight: bold;
}


// Ajax cart header
//
.ajax-cart__header {
  background-color: $color--green;
  padding: 1rem 2rem;
  color: $white;
  font-size: 1.125rem;
}

// Ajax cart container
//
.ajax-cart__content {
  padding: 1rem;
  @include media-breakpoint-up(lg) {
    padding: 1rem 2rem;
  }
}

.ajax-cart__item:not(:last-child) {
  border-bottom: 1px solid $color--gray-lightest;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
}

// Ajax cart footer
//
.ajax-cart__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid $color--green-light;
  //padding: .5rem;
  @include media-breakpoint-up(lg) {
    //padding: .5rem 2rem;
  }
}

.ajax-cart__total {
  margin-right: 1rem;
}

.dc-ajax-add-cart-checkout-cart,
.dc-ajax-add-cart-checkout-link {
  @include button;
  @include button-sizing('large');
  @include button-primary;
  border-radius: 0;
}


// Product list in the cart
//

.product-ajax-cart {
  display: flex;
  align-items: center;

  &__image {
    width: 120px;
    margin-right: 1rem;
    @include media-breakpoint-down(md) {
      width: auto;
    }
    img {
      @include img-fluid;
    }
  }

  &__title {
    color: $color--gray-dark;
    font-weight: bold;
  }

  &__price {
  }

  .price__amount {
    margin-right: .2rem;
  }
}
